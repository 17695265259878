import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import { Button } from 'primereact/button';
import { Calendar } from 'react-big-calendar';
import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import DoAutoCompleteField from '../../Form/Fields/DoAutoCompleteField';
const UpdateAppointmentModal = ({ eventDate,  startTime, endTime, control,handleStatusChange }) => {
    const {
        handleSubmit,
        register,
        reset,
        setValue,
        getValues,
        formState: { errors },
        // control,
      } = useForm(
          // {resolver: yupResolver(schema),}
        );    const onFormSubmit = data => {
        onSubmit(data); 
    };
    useEffect(() => {
        setValue('eventDate', eventDate);
        setValue('startTime', startTime);
        setValue('endTime', endTime);
      }, [eventDate, startTime, endTime, setValue]);
  
    const statusOptions = [
        { label: 'Unconfirmed', value: 'unconfirmed', color: '#FF6347' }, // Tomato
        { label: 'Arrived', value: 'arrived', color: '#32CD32' }, // Lime Green
        { label: 'Ready', value: 'ready', color: '#FFD700' }, // Gold
        { label: 'In Room', value: 'inroom', color: '#1E90FF' }, // Dodger Blue
        { label: 'Checkout', value: 'checkout', color: '#FF69B4' }, // Hot Pink
        { label: 'Left Msg', value: 'leftmsg', color: '#8A2BE2' }, // Blue Violet
        { label: 'Bad Num', value: 'badnum', color: '#A52A2A' }, // Brown
        { label: 'E-mailed', value: 'emailed', color: '#20B2AA' }, // Light Sea Green
        { label: 'Texted', value: 'texted', color: '#FFA500' }, // Orange
        { label: 'E-Confirm Sent', value: 'econfirmsent', color: '#778899' }, // Light Slate Gray
        { label: 'E-Confirm Call', value: 'econfirmcall', color: '#DB7093' }, // Pale Violet Red
        { label: 'E-Confirm Fault', value: 'econfirmfault', color: '#2E8B57' }, // Sea Green
        { label: 'Web Sched', value: 'websched', color: '#6A5ACD' }, // Slate Blue
        { label: 'Out The Door', value: 'outthedoor', color: '#FF4500' } // Orange Red
    ];

    let getAutoComplete = (i, item) => {
        const itemTemplate = (option) => {
          const displayText = item.showField
            ? `${option[item.searchField]} (Name: ${option[item.showField]})`
            : option[item.searchField];
    
          return (
            <div className="autocomplete-item">
              <div>{displayText}</div>
            </div>
          );
        };
    
        
    
        return (
          <div>
            <Controller
              name={item.name}
              control={control}
              render={({ field, fieldState }) => (
                <DoAutoCompleteField
                  markReq={item.required}
                  input={field}
                  id={field.id}
                  name={field.name}
                  field={field}
                  filterField={item.filterField}
                  filterValue={item.filterValue}
                  filterType={item.isNotEq}
                  multiple={item.isMultiple}
                  fieldState={fieldState}
                  errors={errors}
                  screen={'Appointments'}
                  searchApi={item.searchApi}
                  itemTemplate={itemTemplate}
                  searchField={item.searchField}
                  // allow={props.allowDuplicates}
                  filterFieldType={item.filterFieldType ? item.filterFieldType : null}
                  placeholder={item.placeholder}
                  label={item.label}
                  item={item}
                  formType={"edit"}
                />)}
            />
          </div>
        )
      }
    return (
        <div>

            <div className="calendar-form-group">
                <div className="calendar-form-group calendar-form-group-date">
                    <label htmlFor="eventDate">Date</label>
                    <Controller
                        name="eventDate"
                        control={control}
                        render={({ field }) => (
                            <PrimeCalendar id="eventDate" {...field} dateFormat="yy-mm-dd" showIcon />
                        )}
                    />
                </div>

                <div className="calendar-form-group-row">
                    <div className="calendar-form-group calendar-form-group-time">
                        <label htmlFor="startTime">From Time</label>
                        <Controller
                            name="startTime"
                            control={control}
                            render={({ field }) => (
                                <PrimeCalendar id="startTime" {...field} timeOnly hourFormat="24" showIcon />
                            )}
                        />
                    </div>

                    <div className="calendar-form-group calendar-form-group-time">
                        <label htmlFor="endTime">To Time</label>
                        <Controller
                            name="endTime"
                            control={control}
                            render={({ field }) => (
                                <PrimeCalendar id="endTime" {...field} timeOnly hourFormat="24" showIcon />
                            )}
                        />
                    </div>
                </div>
                <div>
                    {getAutoComplete(1, { name: 'provider', type: 'text', label: 'provider', searchApi: "users", searchField: "name" })}
                </div>
                <div>
                    {getAutoComplete(1, { name: 'patientId', type: 'text', label: 'Patient Id', searchApi: "patients", searchField: "patientId", showField: "firstName" })}
                </div>
                <label htmlFor="confirmationStatus">Confirmation Status</label>
                <Controller
                    name="confirmationStatus"
                    control={control}
                    render={({ field }) => (
                        <ListBox
                            {...field}
                            options={statusOptions}
                            optionLabel="label"
                            onChange={handleStatusChange}
                            style={{ width: '100%' }}
                            listStyle={{ height: '250px' }}
                            className="my-listbox"
                        />
                    )}
                />
            </div>

        </div>
    );
};

export default UpdateAppointmentModal;