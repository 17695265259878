import React, { forwardRef, useImperativeHandle, useState, useEffect, useRef } from 'react';
import { withTranslation } from 'react-i18next';
import { Button } from 'primereact/button';
import { Calendar, momentLocalizer, Views, Navigate } from 'react-big-calendar';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import moment from 'moment';
import { useForm, Controller } from 'react-hook-form';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
// import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
// import 'react-big-calendar/lib/css/react-big-calendar.css';
import '../../../scss/component/bigcalendar.scss';
import '../../../scss/component/draganddrop.scss';
import formatDate from '../../UI/FormatDate/formatDate';
import { Dialog } from 'primereact/dialog';
import fetchMethodRequest from '../../../config/service';
import { InputText } from 'primereact/inputtext';
import apiCalls from '../../../config/apiCalls';
import DoMultiSelectField from './DoMultiSelectField';
import DoAutoCompleteField from './DoAutoCompleteField';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faBookmark, faBan, faCalendar, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ColorPicker } from 'primereact/colorpicker';
import { ListBox } from 'primereact/listbox';
import { SketchPicker } from 'react-color';
import ColorPickerWithTooltip from './ColorPickerWithTooltip';
import { Tooltip, Overlay } from 'react-bootstrap';
import { Toast } from 'primereact/toast';
import TabbedInterface from '../../Cruds/CommonModals/AddAppointment';
import CreateSlotModal from '../../Cruds/CommonModals/AddSlot';
import UpdateAppointmentModal from '../../Cruds/CommonModals/UpdateAppointment';

/**
 * A React component that renders a draggable and droppable big calendar for managing events and appointments.
 * It includes functionalities to create, update, and display slots and appointments.
 *
 * @param {Object} props - The props passed to the component.
 * @param {Ref} ref - A ref object for parent components to interact with this component.
 */
const DoBigCalendar = forwardRef((props, ref) => {


  const [events, setEvents] = useState([]);
  const [dialogVisible, setDialogVisible] = useState(false);
  const [eventName, setEventName] = useState('');
  const [eventDate, setEventDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [changedEvents, setChangedEvents] = useState([]);
  const [dialogTitle, setDialogTitle] = useState('');
  const [eventNameLabel, setEventNameLabel] = useState('');
  const [currentAction, setCurrentAction] = useState(null);
  const [errorDialogVisible, setErrorDialogVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [resources, setResources] = useState([]);
  const [slotsEvents, setSlotsEvents] = useState([]);
  const [appointmentsEvents, setAppointmentsEvents] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [cancelAppointmentId, setCancelAppointmentId] = useState([]);
  const [isMatchingSlotFound, setIsMatchingSlotFound] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(null);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);
  const [eventToCancel, setEventToCancel] = useState(null);
  const [draggedEvent, setDraggedEvent] = useState(null);
  const [isDragConfirmDialogVisible, setIsDragConfirmDialogVisible] = useState(false);
  const [slots, setSlots] = useState([]);
  const [resizeEvent, setResizeEvent] = useState(null);
  const [isResizeConfirmDialogVisible, setIsResizeConfirmDialogVisible] = useState(false);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [slotModelTrue, setSlotModelTrue] = useState(null);
  const [handlers, setHandlers] = useState([]);
  const [selectedHandler, setSelectedHandler] = useState(null);
  const [selectedHospitalId, setSelectedHospitalId] = useState(null);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [eventToDelete, setEventToDelete] = useState(null);
  const [date, setDate] = useState(new Date());
  const toast = useRef(null);

  const {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
    control,
  } = useForm(
      // {resolver: yupResolver(schema),}
    );

  useEffect(() => {
    const elements = document.getElementsByClassName('sketch-picker color-picker');

    if (elements && elements.length > 0) {
      const element = elements[0];

      if (element.childNodes.length > 0) {
        element.childNodes[0].style.display = 'none';
        element.childNodes[1].style.display = 'none';
        element.childNodes[2].style.display = 'none';
      }
    }
  });

  /**
  * Fetches handler data and sets the resources state.
  * This effect runs once on component mount.
  */
  useEffect(() => {
    const selectedOrthoIndex = resources.findIndex(r => r.resourceId === props.selectedHandler?._id);
    if (selectedOrthoIndex > 0) {
      const selectedOrtho = resources[selectedOrthoIndex];
      resources.splice(selectedOrthoIndex, 1);
      resources.unshift(selectedOrtho);
      setResources([...resources]);
    }

    getSlotsData();
    getSlotsAndAppointmentsData();
  }, [props.selectedHandler]);

  /**
  * Fetches slots and appointments data based on selected handler and hospital.
  * This effect runs when selectedHandler or selectedHospital props change.
  */
  useEffect(() => {
    getSlotsData();
    getSlotsAndAppointmentsData();
  }, [props.selectedHospital]);

  /**
   * Combines slots and appointments data into a single events array.
   * This effect runs when either appointmentsEvents or slotsEvents change.
   */
  useEffect(() => {
    // This will be triggered whenever either appointmentsEvents or slotsEvents changes
    updateCombinedEvents();
  }, [appointmentsEvents, slotsEvents]);


  useEffect(() => {
    if (resources.length > 0) {
      getSlotsData();
      getSlotsAndAppointmentsData();
    }
  }, [resources]);



  useEffect(() => {
    fetchMethodRequest('GET', apiCalls.users)
      .then(response => {
        if (response) {
          const handler = response.users.filter(emp => emp.role === 'Operative');
          const handlerResources = handler.map(opt => ({
            resourceId: opt._id,
            resourceTitle: opt.name,
          }));
          setResources(handlerResources);
          setHandlers(handler);
        }
      })
      .catch(error => {
        console.error('Error fetching users:', error);
      });
  }, []);


  useEffect(()=>{
    getSlotsAndAppointmentsData()
  },[localStorage.getItem('PatientData')])

  const getSlotsAndAppointmentsData = () => {

    let filterCriteria = {
      limit: 30,
      page: 1,
      sortfield: "created",
      direction: "desc",
      criteria: []
    };

    if (props.selectedHandler) {
      filterCriteria.criteria.push({
        key: "handler",
        value: props.selectedHandler._id,
        type: "eq"
      });
    }

    if (props.selectedHospital) {
      filterCriteria.criteria.push({
        key: "hospitalId",
        value: props.selectedHospital._id,
        type: "eq"
      });
    }

    if(localStorage.getItem('PatientData')){//Filter the Data by the Selected Patient on the Nav Bar
      let patientData=JSON.parse(localStorage.getItem('PatientData'));
      filterCriteria.criteria.push({
        key: "patientId",
        value: patientData._id,
        type: "eq"
      });
    }
    const encodedFilter = encodeURIComponent(JSON.stringify(filterCriteria));

    const requestUrl = `${apiCalls.appointments}?searchFrom=autoComplete&filter=${encodedFilter}`;

    // Fetch appointments data
    fetchMethodRequest('GET', requestUrl)
      .then(async (response) => {
        if (response && response.appointments) {
          const appointmentEvents = [];

          let appointmentsData = response.appointments;

          setAppointments(appointmentsData);

          appointmentsData.forEach(appointment => {

            appointment.slot.forEach(slot => {
              const dateComponents = new Date(slot.date);
              const fromTimeComponents = new Date(slot.fromTime);
              const startHour = fromTimeComponents.getHours();
              const startMinute = fromTimeComponents.getMinutes();

              const toTimeComponents = new Date(slot.toTime);
              const endHour = toTimeComponents.getHours();
              const endMinute = toTimeComponents.getMinutes();

              let handler = slot.operative ? `${slot.operative.name} - ` :
                slot.handler ? `${slot.handler.name} - ` :
                  '';
              let patientName = appointment.patientId ? appointment.patientId.name : '';
              // const title = `${handler} - ${patientName} - ${slot.status}`;

              let patientGender = appointment.patientId.gender;
              let patientNumber = appointment.patientId.phoneNumber;
              let patientAge = appointment.patientId.age;
              let patientAddress = appointment.patientId.address;
              let patientTelephone = appointment.patientId.telePhoneNumber;
              let status = slot.status;
              let title = `${patientName} \nCell:${patientNumber}`;
              // Combine date with start and end times
              const startDateTime = new Date(dateComponents);
              startDateTime.setHours(startHour, startMinute);
              const endDateTime = new Date(dateComponents);
              endDateTime.setHours(endHour, endMinute);
              const handlerResource = resources.find(hd => hd.resourceId === appointment.handler._id);              // Create an event object and push to events array
              const appointmentId = appointment._id;
              const isAppointment="True";
              appointmentEvents.push({
                _id: slot._id,
                title: title,
                start: startDateTime,
                end: endDateTime,
                allDay: false,
                resourceId: handlerResource ? handlerResource.resourceId : undefined,
                patientGender: patientGender,
                patientNumber: patientNumber,
                patientAge: patientAge,
                patientName: patientName,
                patientAddress: patientAddress,
                handler: handler,
                confirmationStatus: appointment.confirmationStatus,
                statusColor: getStatusColor(appointment.confirmationStatus),
                appointmentId: appointmentId,
                date: dateComponents,
                patientTelephone: patientTelephone,
                status: status,
                isAppointment:isAppointment
              });
            });
          });

          // Update the events state with both slots and appointments data
          setAppointmentsEvents(appointmentEvents);
        }
      }).catch(error => {
        console.error('Error fetching appointments:', error);
      });

  };

  /**
  * Fetches slot data based on filters and updates the state.
  */
  const getSlotsData = () => {

    let filterCriteria = {
      limit: 30,
      page: 1,
      sortfield: "created",
      direction: "desc",
      criteria: []
    };

    if (props.selectedHandler) {
      filterCriteria.criteria.push({
        key: "handler",
        value: props.selectedHandler._id,
        type: "eq"
      });
    }

    if (props.selectedHospital) {
      filterCriteria.criteria.push({
        key: "hospitalId",
        value: props.selectedHospital._id,
        type: "eq"
      });
    }


    const encodedFilter = encodeURIComponent(JSON.stringify(filterCriteria));

    const requestUrl = `${apiCalls.slots}?searchFrom=autoComplete&filter=${encodedFilter}`;

    fetchMethodRequest('GET', requestUrl)
      .then(async (response) => {
        if (response && response.slots) {
          let slotsData = response.slots;
          setSlots(slotsData);
          const defaultEvents = [];
          const slotOptions = [];

          slotsData.forEach((slot, index) => {
            if (slot.status === 'Available') {
              const dateComponents = new Date(slot.date);

              const fromTimeComponents = new Date(slot.fromTime);
              const startHour = fromTimeComponents.getHours();
              const startMinute = fromTimeComponents.getMinutes();

              const toTimeComponents = new Date(slot.toTime);
              const endHour = toTimeComponents.getHours();
              const endMinute = toTimeComponents.getMinutes();

              let handler = slot.handler ? `${slot.handler.name} - ` :
                slot.operative ? `${slot.operative.name} - ` :
                  '';
              let status = slot.status;
              const title = `${handler} - ${slot.status}`;

              // const title = `${slot.status}`;
              // Combine date with start time
              const startDateTime = new Date(dateComponents);
              startDateTime.setHours(startHour, startMinute);

              // Combine date with end time
              const endDateTime = new Date(dateComponents);
              endDateTime.setHours(endHour, endMinute);
              const handlerResource = resources.find(hd => hd.resourceId === slot.handler._id);
              const isSlot="True";
              defaultEvents.push({
                _id: slot._id,
                title: title,
                start: startDateTime,
                end: endDateTime,
                allDay: false,
                handler: handler,
                resourceId: handlerResource ? handlerResource.resourceId : undefined,
                status: status,
                date: dateComponents,
                isSlot:isSlot
              });

              slotOptions.push({
                value: slot._id,
                label: title
              });
            }
          });
          // setEvents(defaultEvents);
          setEvents(defaultEvents);
          setSlotsEvents(defaultEvents);
          setDropdownOptions(slotOptions);
        }
      }).catch(error => {
        console.error('Error fetching slots:', error);
      });
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = '';
    let style = {
      borderRadius: '0px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block'
    };

    const matchingAppointment = appointments.find(appointment =>
      appointment.slot.some(slot => slot._id === event._id)
    );

    if (matchingAppointment) {
      backgroundColor = `${matchingAppointment.color}`;
    }

    style.backgroundColor = backgroundColor;

    return {
      style: style,
    };
  };

  /**
   * Updates the events array based on changes in appointments or slots.
  */
  const updateCombinedEvents = () => {
    const combinedEvents = [...appointmentsEvents, ...slotsEvents];
    setEvents(combinedEvents);
  };

  /**
   * Handles updating the state when an event is changed (e.g., dragged or resized).
   * @param {Object} updatedEvent - The event data that has been updated.
   */
  const handleEventChange = (updatedEvent) => {
    setChangedEvents((prevChangedEvents) => {
      const existingIndex = prevChangedEvents.findIndex(event => event._id === updatedEvent._id);
      if (existingIndex > -1) {
        return prevChangedEvents.map((event, index) => index === existingIndex ? updatedEvent : event);
      } else {
        return [...prevChangedEvents, updatedEvent];
      }
    });
  };


  const createAppointment = (data, start, end, startDate, patientId, treatmentType, color, provider) => {
    let newEventOrUpdatedEvent;
    const formattedDate = formatDate.formatDate(startDate);;
    const fromTimePart = formatDate.formatDate(start);
    const toTimePart = formatDate.formatDate(end);
    if (selectedEvent && selectedEvent._id) {
      const updatedEvents = events.map(event => {
        if (event._id === selectedEvent._id) {
          newEventOrUpdatedEvent = {
            title: data.eventName,
            fromTime: fromTimePart,
            toTime: toTimePart,
            date: formattedDate,
            allDay: false,
            status: "Available",
            patientId: patientId,
            treatmentType: treatmentType,
            color: (color && color.hex) || '#265985',
            provider: provider,
            handler: selectedHandler,
            hospitalId: selectedHospitalId

          };
          return newEventOrUpdatedEvent;
        }
        return event;
      });
      setEvents(updatedEvents);
    }

    // Update the changed events state
    handleEventChange(newEventOrUpdatedEvent);


    setDialogVisible(false);


  };

  // Function to handle creating a slot
  const createSlot = (data, start, end, startDate, status) => {
    let handler = props.formValues("handler");
    const formattedDate = formatDate.formatDate(startDate);
    const fromTimePart = formatDate.formatDate(start);
    const toTimePart = formatDate.formatDate(end);
    const slotData = {
      date: formattedDate,
      fromTime: fromTimePart,
      toTime: toTimePart,
      handler: selectedHandler,
      hospitalId: selectedHospitalId,
      status: "Available"
    };
    setIsLoading(true);
    const requestUrl = apiCalls.slots;
    fetchMethodRequest('POST', requestUrl, slotData)
      .then(response => {
        setChangedEvents(prevChangedEvents => [...prevChangedEvents, slotData]);
        setDialogVisible(false);
        getSlotsData();
        getSlotsAndAppointmentsData();
        setIsLoading(false);
      })
      .catch(error => {
        console.error('Error creating slot:', error);
        setIsLoading(false);
      });
  };
  /**
  * Handles the form submission for creating or updating appointments and slots.
  * @param {Object} data - Form data from the submission.
  */
  const handleFormSubmit = (data) => {

    const startDate = new Date(data.eventDate);
    const startTime = new Date(data.startTime);
    const endDate = new Date(data.eventDate);
    const endTime = new Date(data.endTime);
    const status = data.status;
    const start = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), startTime.getHours(), startTime.getMinutes());
    const end = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), endTime.getHours(), endTime.getMinutes());
    const patientId = data.patientId;
    const treatmentType = data.treatmentType;
    const color = data.color;
    const provider = data.provider;

    if (currentAction === 'createAppointment' && slotModelTrue === 3) {

      let handler;
      let hospitalId;
      slots.forEach(slot => {
        if (slot._id === selectedEventId) {
          handler = slot.handler;
          hospitalId = slot.hospitalId;
        }
      });
      updateSlot(data, start, end, startDate, handler, hospitalId);
    }
    else if (currentAction === 'createAppointment') {
      createAppointment(data, start, end, startDate, patientId, treatmentType, color, provider);
      if (props.onSubmit) {
        props.onSubmit(data);
      }


    } else if (currentAction === 'createSlot') {
      createSlot(data, start, end, startDate, status);
    } else if (currentAction === 'updateAppointment') {
      let appointmentId;
      let handler;
      let hospitalId;

      appointments.forEach(appointment => {
        appointment.slot.forEach(slot => {
          if (slot._id === selectedEvent._id) {
            appointmentId = appointment._id;
            hospitalId = appointment.hospitalId;
            handler=appointment.handler;
          }
        });
      });

      const formattedDate = formatDate.formatDate(data.eventDate);
      const fromTimePart = formatDate.formatDate(data.startTime);
      const toTimePart = formatDate.formatDate(data.endTime);


      const updatedEventData = {
        title: data.eventName,
        fromTime: fromTimePart,
        toTime: toTimePart,
        date: fromTimePart,
        handler: selectedHandler,
        allDay: false,
        _id: selectedEvent._id
      };

      const requestBody = {
        slotIds: updatedEventData,
        _id: appointmentId,
        patientId: patientId,
        treatmentType: treatmentType,
        hospitalId: hospitalId,
        provider: provider,
        handler:handler,
        confirmationStatus: confirmStatus,
      };

      updateAppointment(requestBody, appointmentId);
    }

  };
  /**
  * Resets the form fields in the dialog.
  */
  const resetDialogForm = () => {
    reset({
      eventName: "", // Reset with the current eventName state
      eventDate: null,
      startTime: null,
      endTime: null,
    });
    setSelectedEvent(null); // Reset the selected event
    setDialogVisible(false)
  };

  /**
  * Populates the form fields with event data when editing an existing event.
  * @param {Object} event - The event object to populate the form with.
 */
  const populateFormWithEventData = (event) => {
    setValue('eventName', event.title);
    setValue('eventDate', event.start);
    setValue('startTime', event.start);
    setValue('endTime', event.end);
  };

  /**
  * Handles the double click event on calendar events.
  * This function is triggered when a user double clicks on an event in the calendar. It is used for editing existing events.
  * @param {Object} event - The event object that was double clicked.
  */
  const onDoubleClickEvent = (event) => {
    const handlerName = props.formValues("handler");
    const hospitalId = props.formValues("hospitalId");
    const patientId = props.formValues("patientId");
    const treatmentType = props.formValues("treatmentType");
    const selectedSlotId = event._id;
    const handler = handlers.find(emp => emp._id === event.resourceId);
    setSelectedHandler(handler);
    setSelectedHospitalId(handler.hospitalsId);
    setSelectedEventId(event._id);
    setEventDate(event.start);
    setStartTime(event.start);
    setEndTime(event.end);
    setCancelAppointmentId(selectedSlotId);

    const matchingAppointment = appointments.some(appointment =>
      appointment.slot.some(slot => slot._id === selectedSlotId)
    );
    setIsMatchingSlotFound(matchingAppointment);
    const matchingAppointmentPatient = appointments.find(appointment =>
      appointment.slot.some(slot => slot._id === selectedSlotId)
    );

    // If an appointment is found, set form values accordingly
    if (matchingAppointmentPatient) {
      setValue('patientId', matchingAppointmentPatient.patientId);
      setValue('provider', matchingAppointmentPatient.provider);
    }

    if (matchingAppointment) {
      setCurrentAction('updateAppointment');
      setDialogTitle('Update Appointment');
      setSelectedEvent({ ...event, _id: event._id });
      setSelectedEventId(event._id);
    } else {
      setCurrentAction('createAppointment');
      setDialogTitle('Details');
      setSelectedEvent({ ...event, _id: event._id });
    }
    setEventNameLabel('Appointment Name');
    setDialogVisible(true);
    populateFormWithEventData(event);
  };


  const localizer = momentLocalizer(moment)
  const DnDCalendar = withDragAndDrop(Calendar);

  /**
   * Handles the drag and drop functionality for events in the calendar.
   * Updates the event's start and end times based on the new position after dragging.
   * @param {Object} event - The event object with the updated position data.
   */
  const onEventDrop = ({ event, start, end }) => {
    if (event.isSlot) {
      const isOverlapping = events.some(e => e.isSlot && e._id !== event._id && (
        ((start >= e.start && start < e.end) ||
          (end > e.start && end <= e.end) ||
          (start < e.start && end > e.end))
      ));

      if (isOverlapping) {
        setErrorMessage("Slot modification failed: The new time overlaps with another slot.");
        setErrorDialogVisible(true);
        return;
      }
    }
    const idx = events.indexOf(event);
    const updatedEvent = { ...event, start, end };
    const updatedEvents = [...events];
    updatedEvents[idx] = { ...event, start, end };
    setEvents(updatedEvents);
    handleEventChange(updatedEvent);
    setDraggedEvent({ event, start, end });
    setIsDragConfirmDialogVisible(true);
    setCurrentDate(start);

  };
  /**
   * Handles the resizing of events in the calendar.
   * Updates the event's start and end times based on the new size after resizing.
   * @param {Object} event - The event object with the updated size data.
   */
  const onEventResize = ({ event, start, end }) => {
    if (event.isSlot) {
      const isOverlapping = events.some(e => 
        e.isSlot && 
        e._id !== event._id && 
        e.resourceId === event.resourceId && 
        (
          (start >= e.start && start < e.end) ||
          (end > e.start && end <= e.end) ||
          (start < e.start && end > e.end)
        )
      );
  
      if (isOverlapping) {
        setErrorMessage("Slot resize failed: The new time overlaps with another slot.");
        setErrorDialogVisible(true);
        return;
      }
    }
    setResizeEvent({ event, start, end });
    setIsResizeConfirmDialogVisible(true);
    setCurrentDate(start);
  };
  
  /**
   * Renders a confirmation dialog for confirming event resize.
   * @returns {JSX.Element} The resize confirmation dialog component.
   */
  const ResizeConfirmDialog = () => (
    <Dialog
      header="Confirm Event Resize"
      visible={isResizeConfirmDialogVisible}
      onHide={() => setIsResizeConfirmDialogVisible(false)}
      footer={
        <div>
          <Button label="Yes" onClick={confirmResizeChange} />
          <Button label="No" onClick={() => setIsResizeConfirmDialogVisible(false)} />
        </div>
      }
    >
      <p>Do you want to update the event time?</p>
    </Dialog>
  );
  /**
   * Confirms the event resize change and updates the event data accordingly.
   */
  const confirmResizeChange = async () => {
    if (resizeEvent) {
      const updatedEvent = events.find(e => e._id === resizeEvent.event._id);
      if (updatedEvent) {
        const eventId = updatedEvent.appointmentId ? updatedEvent.appointmentId : updatedEvent._id;

        let requestUrl;
        let requestBody;

        if (updatedEvent.handler && updatedEvent.status) {
          let hospitalId, handler;
          slots.forEach(slot => {
            if (slot._id === updatedEvent._id) {
              hospitalId = slot.hospitalId;
              handler = slot.handler;
            }
          });

          requestBody = {
            _id: eventId,
            fromTime: formatDate.formatDate(resizeEvent.start),
            toTime: formatDate.formatDate(resizeEvent.end),
            date: formatDate.formatDate(resizeEvent.start),
            hospitalId: hospitalId,
            handler: handler
          };
          requestUrl = `${apiCalls.slots}/${updatedEvent._id}`;
        } else if (updatedEvent.patientName && updatedEvent.patientNumber) {
          let hospitalId, handler, patientId;

          appointments.forEach(appointment => {
            if (appointment._id === updatedEvent.appointmentId) {
              hospitalId = appointment.hospitalId;
              handler = appointment.handler;
              patientId = appointment.patientId;
            }
          });
          requestBody = {
            slotIds: {
              _id: updatedEvent._id,
              fromTime: formatDate.formatDate(resizeEvent.start),
              toTime: formatDate.formatDate(resizeEvent.end),
              date: formatDate.formatDate(resizeEvent.start),
              handler: handler,
              hospitalId:hospitalId
            },
            hospitalId: hospitalId,
            handler: handler,
            patientId: patientId,
            _id: eventId
          };
          requestUrl = `${apiCalls.resizing}/${updatedEvent.appointmentId}`;
        } else {
          console.error('Event does not meet criteria for either slots or appointments update');
          return;
        }

        try {
          setIsLoading(true);
          const response = await fetchMethodRequest('PUT', requestUrl, requestBody);
          if (response && response.success) {
            // Update state to reflect the changes in UI
            const updatedEvents = events.map(evt => {
              if (evt._id === resizeEvent.event._id) {
                return { ...evt, start: resizeEvent.start, end: resizeEvent.end };
              }
              return evt;
            });
            setEvents(updatedEvents);
          } else {
            console.error('Update failed:', response);
          }
        } catch (error) {
          console.error('Error updating event:', error);
        } finally {
          setIsLoading(false);
          getSlotsData();
          getSlotsAndAppointmentsData();
        }
      }
    }
    setIsResizeConfirmDialogVisible(false);
    setResizeEvent(null);
  };


  const createDefaultTime = () => {
    const defaultDateTime = new Date();
    defaultDateTime.setHours(12, 0, 0, 0);
    return defaultDateTime;
  };

  /**
   * Renders a confirmation dialog for confirming changes made by dragging events.
   * It prompts the user to confirm or cancel the changes made by dragging an event to a new time slot.
   * @returns {JSX.Element} The drag confirmation dialog component.
   */
  const DragConfirmDialog = () => (
    <Dialog
      header="Confirm Event Change"
      visible={isDragConfirmDialogVisible}
      onHide={() => setIsDragConfirmDialogVisible(false)}
      footer={
        <div>
          <Button label="Yes" onClick={confirmDragChange} />
          <Button label="No" onClick={() => setIsDragConfirmDialogVisible(false)} />
        </div>
      }
    >
      <p>Do you want to update the event time?</p>
    </Dialog>
  );

  /**
   * Confirms the changes made by dragging an event and updates the event data accordingly.
   * It is called when the user confirms the changes in the drag confirmation dialog.
   */
  const confirmDragChange = async () => {
    if (draggedEvent) {
      const updatedEvent = events.find(e => e._id === draggedEvent.event._id);
      if (updatedEvent) {
        const eventId = updatedEvent.appointmentId ? updatedEvent.appointmentId : updatedEvent._id;

        let requestUrl;
        let requestBody;

        if (updatedEvent.handler && updatedEvent.status) {
          let handler;
          let hospitalId;

          slots.forEach(slot => {
            if (slot._id === updatedEvent._id) {
              handler = slot.handler;
              hospitalId = slot.hospitalId;
            }
          });
          // For slots
          requestBody = {
            _id: eventId,
            fromTime: formatDate.formatDate(draggedEvent.start),
            toTime: formatDate.formatDate(draggedEvent.end),
            date: formatDate.formatDate(draggedEvent.start),
            handler: handler,
            hospitalId: hospitalId,

          };
          requestUrl = `${apiCalls.slots}/${updatedEvent._id}`;
        } else if (updatedEvent.patientName && updatedEvent.patientNumber) {
          // For appointments
          let handler;
          let hospitalId;

          let patientId;
          appointments.forEach(appointment => {
            if (appointment._id === updatedEvent.appointmentId) {
              handler = appointment.handler;
              hospitalId = appointment.hospitalId;
              patientId = appointment.patientId;
            }
          });
          requestBody = {
            slotIds: {
              _id: updatedEvent._id,
              fromTime: formatDate.formatDate(draggedEvent.start),
              toTime: formatDate.formatDate(draggedEvent.end),
              date: formatDate.formatDate(draggedEvent.start),
              handler: handler,
              hospitalId:hospitalId
            },
            handler: handler,
            hospitalId: hospitalId,
            patientId: patientId,
            _id: eventId
          };
          requestUrl = `${apiCalls.dragAndDrop}/${updatedEvent.appointmentId}`;
        } else {
          console.error('Event does not meet criteria for either slots or appointments update');
          return;
        }

        try {
          setIsLoading(true);
          const response = await fetchMethodRequest('PUT', requestUrl, requestBody);
          if (response && response.success) {
            setDialogVisible(false);
          } else {
            console.error('Update failed:', response);
          }
        } catch (error) {
          console.error('Error updating event:', error);
        } finally {
          setIsLoading(false);
          getSlotsAndAppointmentsData();
          getSlotsData();
        }
      }
    }
    setIsDragConfirmDialogVisible(false);
    setDraggedEvent(null);
  };

  /**
   * Handles the selection of time slots in the calendar.
   * This function is triggered when a user selects a time slot. It is used for creating new events.
   * @param {Object} slotInfo - Contains information about the selected time slot.
   */
  const handleSelectSlot = ({ start, end, resourceId }) => {
    // Check if the selected slot overlaps with any existing slots
    const isOverlapping = events.some(event => {
      return (
        resourceId === event.resourceId &&
        ((start >= event.start && start < event.end) ||
          (end > event.start && end <= event.end) ||
          (start < event.start && end > event.end))
      );
    });

    if (isOverlapping) {
      // Set error message and show error dialog
      setErrorMessage("Slot creation failed: Selected time overlaps with an existing slot. Please choose another time.");
      setErrorDialogVisible(true);
      return;
    }
    const handler = handlers.find(emp => emp._id === resourceId);


    setSelectedHospitalId(handler.hospitalsId);

    setSelectedHandler(handler);
    setEventDate(start);
    setStartTime(start);
    setEndTime(end);
    setCurrentDate(start);
    setCurrentAction('createSlot');
    setDialogVisible(true);
    setDialogTitle('Details');
    setEventNameLabel('Slot Name');

    setValue('eventDate', start);
    setValue('startTime', start);
    setValue('endTime', end);
  };

  const onHide = () => {
    reset({
      eventName: "",
      eventDate: null,
      startTime: null,
      endTime: null,
    });
    setDialogVisible(false);
  };
  /**
   * Imperative handle hook to expose component methods to parent components.
   * It allows parent components to directly interact with the methods of this component.
   */
  useImperativeHandle(ref, () => ({
    getEvents: () => {
      return changedEvents;
    },
    setCloseDialogVisible: () => {
      setDialogVisible(false);
      getSlotsAndAppointmentsData();
      getSlotsData();
      setValue('patientId', '');
      setValue('provider', '');
      setSlotModelTrue(0);
    },
  }));

  const showCancelConfirmationDialog = (event) => {
    setEventToCancel(event);
    setShowCancelConfirmation(true);
  };

  /**
   * Renders a confirmation dialog for canceling an appointment.
   * It prompts the user to confirm the cancellation of an appointment.
   * @returns {JSX.Element} The cancellation confirmation dialog component.
   */
  const CancelConfirmationDialog = () => (
    <Dialog
      header="Confirm Cancellation"
      visible={showCancelConfirmation}
      style={{ width: '50vw' }}
      onHide={() => setShowCancelConfirmation(false)}
      footer={
        <div>
          <Button label="Yes" onClick={confirmCancellation} />
          <Button label="No" onClick={() => setShowCancelConfirmation(false)} />
        </div>
      }
    >
      <p>Do you want to cancel this appointment?</p>
    </Dialog>
  );
  const confirmCancellation = () => {
    if (eventToCancel) {
      cancelAppointmentForSlot(eventToCancel);
    }
    setShowCancelConfirmation(false);
  };

  const cancelAppointmentForSlot = async (eventData) => {

    // Find the matching appointment
    const matchingAppointment = appointments.find(appointment =>
      appointment.slot.some(slot => slot._id === eventData._id)
    );

    if (!matchingAppointment) {
      console.log('No matching appointment found for the selected slot');
      return;
    }

    const data = matchingAppointment;
    const requestUrl = `${apiCalls.slotcancel}`;

    // Execute the API call to cancel the appointment
    fetchMethodRequest('POST', requestUrl, data)
      .then(response => {
        getSlotsAndAppointmentsData();
        getSlotsData();
      })
      .catch(error => {
        console.error('Error canceling appointment:', error);
      })
      .finally(() => {
        // Resetting cancelAppointmentId after the API call, regardless of the result
        setCancelAppointmentId(null);
      });
  };

  /**
   * Updates an existing appointment with new data.
   * This function is called when updating an appointment through the UI.
   * @param {Object} requestBody - The updated data for the appointment.
   * @param {string} appointmentId - The ID of the appointment to update.
   */
  const updateAppointment = async (requestBody, appointmentId) => {
    const requestUrl = `${apiCalls.appointments}/${appointmentId}`;
    try {
      setIsLoading(true);
      const response = await fetchMethodRequest('PUT', requestUrl, requestBody);
      if (response && response.message === "Confirmation status updated successfully") {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'Appointment updated successfully!' });
        setDialogVisible(false);
        setValue('patientId', '');
        setValue('provider', '');
      } else {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update appointment.' });
      }
    } catch (error) {
      console.error('Error updating appointment:', error);
    } finally {
      setIsLoading(false);
      getSlotsAndAppointmentsData();
      getSlotsData();
    }
  };
  const updateSlot = async (data, start, end, startDate, handler, hospitalId) => {
    const requestUrl = `${apiCalls.slots}/${selectedEvent._id}`;

    let requestBody = {
      fromTime: start,
      toTime: end,
      date: startDate,
      handler: handler,
      hospitalId: hospitalId,
      _id:selectedEvent._id
    }
    try {
      setIsLoading(true);
      const response = await fetchMethodRequest('PUT', requestUrl, requestBody);
      if (response && response.respMessage === "Slots updated successfully.") {
        toast.current.show({ severity: 'success', summary: 'Success', detail: 'slots updated successfully!' });
        setDialogVisible(false);
        setValue('provider', '');
      } else {
        toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to update slot.' });
      }
    } catch (error) {
      console.error('Error updating appointment:', error);
    } finally {
      setIsLoading(false);
      getSlotsAndAppointmentsData();
      getSlotsData();
    }
  };
  const confirmDeletion = (event) => {
    setEventToDelete(event);
    setShowDeleteConfirmation(true);
  };

  const DeleteConfirmationDialog = () => (
    <Dialog
      header="Confirm Deletion"
      visible={showDeleteConfirmation}
      onHide={() => setShowDeleteConfirmation(false)}
      footer={
        <div>
          <Button label="Yes" onClick={() => handleDeleteConfirmed(eventToDelete)} />
          <Button label="No" onClick={() => setShowDeleteConfirmation(false)} />
        </div>
      }
    >
      <p>Are you sure you want to delete this event?</p>
    </Dialog>
  );


  const handleDeleteConfirmed = (event) => {
    if (event) {
      let requestUrl;
      if (event.appointmentId) {
        requestUrl = `${apiCalls.appointments}/${event.appointmentId}`;
      } else {
        // URL for deleting a slot
        requestUrl = `${apiCalls.slots}/${event._id}`;
      }

      // API call to delete the slot or appointment
      fetchMethodRequest('DELETE', requestUrl)
        .then(response => {
          if (response.success) {
            // Remove the event from the state
            setEvents(events.filter(event => event._id !== event._id));
            toast.current.show({ severity: 'success', summary: 'Success', detail: 'Event deleted successfully!' });
          } else {
            toast.current.show({ severity: 'error', summary: 'Error', detail: 'Failed to delete event.' });
          }
        })
        .catch(error => {
          console.error('Error deleting event:', error);
          toast.current.show({ severity: 'error', summary: 'Error', detail: 'Error occurred while deleting event.' });
        })
        .finally(() => {
          setShowDeleteConfirmation(false);
          setEventToDelete(null);
          getSlotsAndAppointmentsData();
          getSlotsData();
        });
    }
  };

  let getAutoComplete = (i, item) => {
    const itemTemplate = (option) => {
      const displayText = item.showField
        ? `${option[item.searchField]} (Name: ${option[item.showField]})`
        : option[item.searchField];

      return (
        <div className="autocomplete-item">
          <div>{displayText}</div>
        </div>
      );
    };

    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoAutoCompleteField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              filterField={item.filterField}
              filterValue={item.filterValue}
              filterType={item.isNotEq}
              multiple={item.isMultiple}
              fieldState={fieldState}
              errors={errors}
              screen={'Appointments'}
              searchApi={item.searchApi}
              itemTemplate={itemTemplate}
              searchField={item.searchField}
              allow={props.allowDuplicates}
              filterFieldType={item.filterFieldType ? item.filterFieldType : null}
              placeholder={item.placeholder}
              label={item.label}
              item={item}
            />)}
        />
      </div>
    )
  }

  const slotStyleGetter = (date) => {
    const startOfDay = moment(date).startOf('day');
    const minutesSinceStartOfDay = moment(date).diff(startOfDay, 'minutes');
    const isTenMinuteInterval = minutesSinceStartOfDay % 10 === 0;
    const isSixtyMinuteInterval = minutesSinceStartOfDay % 60 === 50;

    if (isSixtyMinuteInterval) {
      return {
        style: {
          borderBottom: '1px solid #008080',
        },
      };
    } else if (isTenMinuteInterval) {
      return {
        style: {
          borderBottom: '1px solid #ccc',
        },
      };
    } else {
      return {};
    }
  };

  const statusOptions = [
    { label: 'Unconfirmed', value: 'unconfirmed', color: '#FF6347' }, // Tomato
    { label: 'Arrived', value: 'arrived', color: '#32CD32' }, // Lime Green
    { label: 'Ready', value: 'ready', color: '#FFD700' }, // Gold
    { label: 'In Room', value: 'inroom', color: '#1E90FF' }, // Dodger Blue
    { label: 'Checkout', value: 'checkout', color: '#FF69B4' }, // Hot Pink
    { label: 'Left Msg', value: 'leftmsg', color: '#8A2BE2' }, // Blue Violet
    { label: 'Bad Num', value: 'badnum', color: '#A52A2A' }, // Brown
    { label: 'E-mailed', value: 'emailed', color: '#20B2AA' }, // Light Sea Green
    { label: 'Texted', value: 'texted', color: '#FFA500' }, // Orange
    { label: 'E-Confirm Sent', value: 'econfirmsent', color: '#778899' }, // Light Slate Gray
    { label: 'E-Confirm Call', value: 'econfirmcall', color: '#DB7093' }, // Pale Violet Red
    { label: 'E-Confirm Fault', value: 'econfirmfault', color: '#2E8B57' }, // Sea Green
    { label: 'Web Sched', value: 'websched', color: '#6A5ACD' }, // Slate Blue
    { label: 'Out The Door', value: 'outthedoor', color: '#FF4500' } // Orange Red
  ];
  const handleStatusChange = (e) => {
    setConfirmStatus(e.value);
    const updatedEvents = events.map(event => {
      if (event._id === selectedEventId) {
        return {
          ...event,
          confirmationStatus: e.value,
          statusColor: getStatusColor(e.value)
        };
      }
      return event;
    });

    setEvents(updatedEvents);
  };
  const getStatusColor = (status) => {
    const statusObj = statusOptions.find(option => option.value === status);
    return statusObj ? statusObj.color : '#000000'; // Default color if status not found
  };

  const EventComponent = ({ event }) => {
    const target = useRef(null);
    const [tooltipShow, setTooltipShow] = useState(false);
    const [tooltipTarget, setTooltipTarget] = useState(null);

    const handleCancelIconClick = () => {
      showCancelConfirmationDialog(event);
    };

    const renderThumbnail = () => {
      if (event.patientName && !event.thumbnailUrl)
        return (
          <div style={{
            width: '100px',
            height: '100px',
            backgroundColor: '#000',
            color: '#fff',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontSize: '0.8em',
            borderRadius: '4px',
            border: '2px solid white'
          }}>
            Patient picture unavailable
          </div>
        );
      console.log("value", value);
    };
    const onMouseEnter = (e) => {
      setTooltipTarget(e.target);
      setTooltipShow(true);
    };

    const onMouseLeave = () => {
      setTooltipShow(false);
    };
    const renderEventContent = () => {
      const content = (
        <div>
          {event.patientName && !event.thumbnailUrl && <div>{renderThumbnail()}</div>}
          <div style={{ textAlign: 'left' }}>
            {event.patientName && <div>{event.patientName}</div>}
            {event.handler && <div> {event.handler}</div>}
            {event.patientAge && <div>Age: {event.patientAge}</div>}
            {event.patientGender && <div> {event.patientGender}</div>}
            {event.patientNumber && <div>Cell: {event.patientNumber}</div>}
            {event.patientTelephone && <div>Tele: {event.patientTelephone}</div>}
            {event.patientAddress && <div>Address: {event.patientAddress}</div>}
            {event.status && <div> {event.status}</div>}
          </div>
        </div>
      );

      if (event.handler && event.status) {
        return (
          <div className="event-content" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            {/* for the Slot we should Display the Empty White space */}
            {/* <p className='handler'>{event.handler}</p>
            <p className='status'>Status: {event.status}</p>
            {tooltipShow && (
              <Overlay target={tooltipTarget} show={tooltipShow} placement="auto">
                <Tooltip id={`tooltip-${event._id}`} className='events-tooltip'>
                  {content}
                </Tooltip>
              </Overlay>
            )} */}
          </div>
        );
      } else if (event.patientName && event.patientNumber) {

        return (
          <div className="event-content" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <p className='patient-name'>{event.patientName}</p>
            <p className='patient-number'>Cell: {event.patientNumber}</p>
            {tooltipShow && (
              <Overlay target={tooltipTarget} show={tooltipShow} placement="auto">
                <Tooltip id={`tooltip-${event._id}`} className='events-tooltip'>
                  {content}
                </Tooltip>
              </Overlay>
            )}
          </div>
        );
      }
      return (
        <div className="event-content" onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
          No details available
          {tooltipShow && (
            <Overlay target={tooltipTarget} show={tooltipShow} placement="auto">
              <Tooltip id={`tooltip-${event._id}`} className='events-tooltip'>
                {content}
              </Tooltip>
            </Overlay>
          )}
        </div>
      );
    };

    return (
      <div className="custom-event" ref={target}>
        <div className="event-icons">
        {event.confirmationStatus && <span>
          <FontAwesomeIcon icon={faBookmark} className="icon-bookmark" />
          <FontAwesomeIcon icon={faCircle} className="icon-circle" style={{ color: event.statusColor }} />
          {event.patientName && event.patientNumber && (
            <FontAwesomeIcon icon={faBan} className="icon-circle" style={{ color: 'black' }} onClick={handleCancelIconClick} />
          )}
          </span>}
          <FontAwesomeIcon icon={faTrash} className='icon-circle' onClick={() => confirmDeletion(event)} />
        </div>
        {renderEventContent()}
      </div>
    );
  };

  const tooltipAccessor = (event) => {

    let tooltipContent = '';
    if (event.patientName) {
      tooltipContent = `\n${event.patientName}`;
    }
    else if (event.handler) {
      tooltipContent = `\n ${event.handler}`;
    }
    else {
      tooltipContent = `\n${event.title}`;
    }
    if (event.patientAge) {
      tooltipContent += `\n ${event.patientAge}`;
    }
    if (event.patientNumber) {
      tooltipContent += `\n${event.patientNumber}`;
    }
    if (event.patientTelephone) {
      tooltipContent += `\n ${event.patientTelephone}`;

    }
    if (event.patientGender) {
      tooltipContent += `\n${event.patientGender}`;
    } if (event.patientAddress) {
      tooltipContent += `\n ${event.patientAddress}`;
    }
    if (event.status) {
      tooltipContent += `\n ${event.status}`;

    }

    return tooltipContent;
  };


  const formats = {
    timeGutterFormat: (date, culture, localizer) => {
      const hours = localizer.format(date, 'hh A', culture); // Includes AM/PM
      const minutes = localizer.format(date, 'mm', culture);

      if (minutes === '00') {
        return `${hours}`;
      }
      return `${minutes}`;
    },
  };

  const CustomToolbar = ({ label,date, onNavigate, onView }) => {
    const [isCalendarVisible, setIsCalendarVisible] = useState(false);


    const navigate = action => {
      onNavigate(action);
    };

    const view = view => {
      onView(view);
    };

    const handleDateSelect = (newDate) => {
      setDate(newDate);
      onNavigate(Navigate.DATE, newDate);
      onView(Views.DAY);
      setIsCalendarVisible(false);
    };

    // Function to toggle the calendar visibility
    const openCalendar = () => {
      setIsCalendarVisible(!isCalendarVisible);
    };
    const formattedLabel = moment(date).format('DD MMMM YYYY');
    return (
      <div className="rbc-toolbar">
        <Toast ref={toast} />
        <span className="rbc-btn-group">
          <button type="button" onClick={() => navigate(Navigate.PREVIOUS)}>Back</button>
          <button type="button" onClick={() => navigate(Navigate.NEXT)}>Next</button>
        </span>

        <div className="date-label-container" >
          <span className="rbc-toolbar-label" onClick={openCalendar}>
            {formattedLabel}
          </span>

          {isCalendarVisible &&
            <div className="calendar-popup">
              <PrimeCalendar
                className='toolbar-calendar'
                value={date}
                onSelect={(e) => handleDateSelect(e.value)}
                inline
              />
            </div>
          }
        </div>
        <span className="rbc-btn-group">
          <button type="button" onClick={() => navigate(Navigate.TODAY)}>Today</button>
          <button type="button" onClick={() => view(Views.DAY)}>Day</button>
          <button type="button" onClick={() => view(Views.WEEK)}>Week</button>
        </span>
      </div>
    );
  };


  const getColumnClass = () => {
    if (dialogVisible) {
      if (currentAction === 'createAppointment') {
        return "col-6";
      } else if (currentAction === 'updateAppointment' || currentAction === 'createSlot') {
        return "col-9";
      }
    }
    return "col-12";
  };

  const getSidebarClass = () => {
    if (dialogVisible) {
      if (currentAction === 'createAppointment') {
        return "col-6";
      } else if (currentAction === 'updateAppointment' || currentAction === 'createSlot') {
        return "col-3";
      }
    }
    return "d-none";
  };
  const handleSlotModal = (value) => {
    setSlotModelTrue(value);
  };
  // Functionality to render different modals based on the current action
  const ModalContainer = ({ currentAction }) => {
    const handleSlotModal = (value) => {
      setSlotModelTrue(value);
    };
    switch (currentAction) {
      case 'createSlot':
        return <CreateSlotModal
          eventDate={eventDate}
          startTime={startTime}
          endTime={endTime}
          getValues={getValues}
        />;
      case 'createAppointment':
        return <TabbedInterface
          eventDate={eventDate}
          startTime={startTime}
          endTime={endTime}
          control={control}
          onHandleSlotModal={handleSlotModal}
          currentAction={currentAction}
          setSelectedEvent={selectedEvent}
          slots={slots}
          setSelectedEventId={setSelectedEventId}
        />;
      case 'updateAppointment':
        return <UpdateAppointmentModal
          eventDate={eventDate}
          startTime={startTime}
          endTime={endTime}
          control={control}
          handleStatusChange={handleStatusChange}
        />;
      default:
        return null;
    }
  };

  /**
   * Handles navigation actions in the calendar (e.g., moving to next/previous month).
   * @param {Date} newDate - The new date to navigate to.
   */
  const handleNavigate = (newDate) => {
    setCurrentDate(newDate);
  };

  /**
   * Returns the appropriate label for the submit button based on the current action.
   * @returns {string} The label for the submit button.
   */
  const getSubmitButtonLabel = () =>
    currentAction === 'createAppointment' && slotModelTrue === 3 ? "Update Slot" : "Submit";

  return (
    <div>
      <Toast ref={toast} />
      {isLoading && (
        <div className="spinner-container">
          <ProgressSpinner />
        </div>
      )}
      <div style={{ paddingTop: "20px" }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
          <h1>Appointment Calendar</h1>
        </div>
        {resources.length === 0 && (
          <div style={{ color: 'red', textAlign: 'right', marginTop: '20px' }}>
            No operatives. Please create operatives to manage the calendar.
          </div>
        )}
        <div className='row'>
          <div className={getColumnClass()}>

            <DnDCalendar
              defaultDate={currentDate}
              resources={resources}
              defaultView="day"
              events={events}
              localizer={localizer}
              onEventDrop={onEventDrop}
              tooltipAccessor={false}
              onEventResize={onEventResize}
              onDoubleClickEvent={onDoubleClickEvent}
              resizable={true}
              onNavigate={handleNavigate}
              selectable={true}
              onSelectSlot={handleSelectSlot}
              resourceTitleAccessor="resourceTitle"
              resourceIdAccessor="resourceId"
              eventPropGetter={eventStyleGetter}
              step={10}
              timeslots={1}
              formats={formats}
              slotPropGetter={slotStyleGetter}
              components={{
                event: EventComponent,
                toolbar: CustomToolbar

              }}
              views={['day', 'week']}
            />
          </div>
          <div className={getSidebarClass()}>
            <div className='calendar-sidebar'>
              <div className="calendar-form-container">
                <h1 className="calendar-form-header">{dialogTitle}</h1>

                <button className="close-button" onClick={onHide}>✕</button>
                <form onSubmit={handleSubmit(handleFormSubmit)} className="calendar-form">
                  <div>
                    {currentAction === 'createSlot' && (
                      <CreateSlotModal
                        eventDate={eventDate}
                        startTime={startTime}
                        endTime={endTime}
                        getValues={getValues}
                      />
                    )}
                    {currentAction === 'createAppointment' && (
                      <TabbedInterface
                        eventDate={eventDate}
                        startTime={startTime}
                        endTime={endTime}
                        control={control}
                        onHandleSlotModal={handleSlotModal}
                        currentAction={currentAction}
                        setSelectedEvent={setSelectedEvent}
                        slots={slots}
                        slotModelTrue={slotModelTrue}
                        setSelectedEventId={setSelectedEventId}
                                              />
                    )}
                    {currentAction === 'updateAppointment' && (
                      <UpdateAppointmentModal
                        eventDate={eventDate}
                        startTime={startTime}
                        endTime={endTime}
                        control={control}
                        handleStatusChange={handleStatusChange}
                      />
                    )}
                  </div>
                  {currentAction !== 'updateAppointment' && (
                    <Button label={getSubmitButtonLabel()} type="submit" className='appointment-submit-btn' />
                  )}

                  {currentAction === 'updateAppointment' && (
                    <Button onClick={(e) => updateAppointment(e)} className='appointment-cancel-btn'>Update</Button>
                  )}
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isResizeConfirmDialogVisible && <ResizeConfirmDialog />}
      {isDragConfirmDialogVisible && <DragConfirmDialog />}

      <CancelConfirmationDialog />
      <DeleteConfirmationDialog />
      <Dialog header="Attention" visible={errorDialogVisible} style={{ width: '50vw' }} onHide={() => setErrorDialogVisible(false)} className="custom-dialog">
        <div>{errorMessage}</div>
        <Button label="OK" onClick={() => setErrorDialogVisible(false)} />
      </Dialog>
    </div>
  )
});

export default DoBigCalendar;





