import React, { PureComponent, useEffect, useState } from 'react';
import {
  Card, CardBody, Col,
} from 'reactstrap';


// config
import fetch from '../../../../config/service';
import apiCalls from '../../../../config/apiCalls';
import config from '../../../../config/config';

import { useForm, Controller } from 'react-hook-form';
import { Button } from 'primereact/button';
// import { Card } from 'primereact/card';
import DoInputField from '../../../Form/Fields/DoInputField';
import DoDateField from '../../../Form/Fields/DoDateField';
import DoPasswordField from '../../../Form/Fields/DoPasswordField';
import DoTextareaField from '../../../Form/Fields/DoTextareaField';
import DoFileUplaod from '../../../Form/Fields/DoFileUpload';
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';



const Profile = (props) => {
 
  const profileFields =  [
    {"name":"firstName","type":"text","placeholder":"First Name","label":"First Name","width":110,"id":"name","displayinaddForm":"true","displayineditForm":"true","displayinlist":"true","controllerName":null,"fieldType":"Link","displayinregisterForm":"true","disabled":false,"globalSearchField":"true","show":true,"addFormOrder":1,"editFormOrder":1,"mobile":true,"displayInSettings":true},
    {"name":"lastName","type":"text","placeholder":"Last Name","label":"Last Name","width":110,"id":"name","displayinaddForm":"true","displayineditForm":"true","displayinlist":"true","controllerName":null,"fieldType":"Link","displayinregisterForm":"true","disabled":false,"globalSearchField":"true","show":true,"addFormOrder":1,"editFormOrder":1,"mobile":true,"displayInSettings":true},
    {"name":"email","type":"email","placeholder":"Email","label":"Email","id":"email","width":150,"displayinaddForm":"true","displayineditForm":"false","displayinlist":"true","controllerName":null,"displayinregisterForm":"true","disabled":false,"show":true,"globalSearchField":"true","addFormOrder":2,"editFormOrder":2,"mobile":true,"displayInSettings":true},
    {"name":"address","type":"textarea","placeholder":"Address","label":"Address","id":"address","width":180,"displayinaddForm":"true","displayineditForm":"true","displayinlist":"true","controllerName":null,"show":true,"disabled":false,"globalSearchField":"true","addFormOrder":3,"editFormOrder":3,"mobile":true,"displayInSettings":true},
    {"name":"phone","type":"number","placeholder":"Phone","label":"Phone","id":"phone","width":110,"displayinaddForm":"true","displayineditForm":"true","displayinlist":"true","controllerName":null,"displayinregisterForm":"true","show":true,"disabled":false,"addFormOrder":5,"editFormOrder":5,"mobile":true,"displayInSettings":true,"fieldType":"Link","style":{"color":"#0e4768","cursor":"pointer","textTransform":"capitalize"},maxLength:config.phoneNumberLength},
    // {"name":"email","type":"email","placeholder":"Email","label":"Email","id":"email","width":150,"displayinaddForm":"true","displayineditForm":"false","displayinlist":"true","controllerName":null,"displayinregisterForm":"true","disabled":true,"show":true,"globalSearchField":"true","mobile":true,"displayInSettings":true}
  ]
 // [{"name":"name","type":"text","placeholder":"Name","label":"Name","width":110,"id":"name","displayinaddForm":"true","displayineditForm":"true","displayinlist":"true","controllerName":null,"fieldType":"Link","displayinregisterForm":"true","disabled":true,"globalSearchField":"true","show":true,"addFormOrder":1,"editFormOrder":1,"mobile":true,"displayInSettings":true},{"name":"email","type":"email","placeholder":"Email","label":"Email","id":"email","width":150,"displayinaddForm":"true","displayineditForm":"false","displayinlist":"true","controllerName":null,"displayinregisterForm":"true","disabled":true,"show":true,"globalSearchField":"true","addFormOrder":2,"editFormOrder":2,"mobile":true,"displayInSettings":true},{"name":"address","type":"textarea","placeholder":"Address","label":"Address","id":"address","width":180,"displayinaddForm":"true","displayineditForm":"true","displayinlist":"true","controllerName":null,"show":true,"disabled":true,"globalSearchField":"true","addFormOrder":3,"editFormOrder":3,"mobile":true,"displayInSettings":true},{"name":"phone","type":"text","placeholder":"Phone","label":"Phone","id":"phone","width":110,"displayinaddForm":"true","displayineditForm":"true","displayinlist":"true","controllerName":null,"displayinregisterForm":"true","show":true,"disabled":true,"addFormOrder":5,"editFormOrder":5,"mobile":true,"displayInSettings":true,"fieldType":"Link","style":{"color":"#0e4768","cursor":"pointer","textTransform":"capitalize"}}]
  
  const [userData,setUserData] = useState({});


  useEffect(() => {
    getUserData();
  }, []);



  let {
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
    control,
  } = useForm();


  // get data from server based on Id
  const getUserData = async () => {
    if (localStorage.getItem('loginCredentials')) {
      let user = JSON.parse(localStorage.getItem('loginCredentials'));
      let Url = `${apiCalls.employee}/${user._id}`;
      return fetch('GET', Url)
        .then(async (response) => {
          if (response && response.details) {
            setUserData({ ...response.details });
            user = Object.assign(user, response.details)
            for (let key in user) {
              setValue(key,user[key]);
            }
            await localStorage.setItem('loginCredentials', JSON.stringify(user));
            if (response.details.photo) {
              updateTopbarData()
              // this.setState({ pic: response.details.photo });
            }
            reset(response.details);
          } else if (response && response.errorMessage) {
          }
        }).catch((err) => {
          return err;
        });
    }
    else {
      return;
    }
  }
  const updateTopbarData = () => {
    // let prevsPros = this.state.locationProps
    // if (prevsPros && prevsPros.location && prevsPros.location.state && prevsPros.location.state.updateTopbarProfileData) {
    //   this.props.locationProps.location.state.updateTopbarProfileData()
    // }
  }
  const getFileName = (file) => {
    updateTopbarData();
  }

  const onClickUpdate = (data) => {
    updateProfile({ ...data });
  }

  const updateProfile = (formData) => {
    if (formData) {
      delete formData.email;
      delete formData.password;
      let Url;
      if (localStorage.getItem('loginCredentials')) {
        let user = JSON.parse(localStorage.getItem('loginCredentials'));
        Url = `${apiCalls.employee}/${user._id}`;
      }
      return fetch('PUT', Url, formData)
        .then(async (response) => {
          if (response && response.respCode && response.respCode === 205) {
            showToasterMessage(response.respMessage, 'success');
            getUserData();
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, 'error');
          }
        }).catch((err) => {
          return err;
        });

    } else {
      return;
    }

  }


    return (
      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <div className="card__title">
              <h5 className="bold-text">Profile</h5>
            </div>
            <form  onSubmit={handleSubmit(onClickUpdate)}>
              
                <div className='row'>
                  {profileFields && profileFields.length > 0 ? profileFields.map((item, index) => (
                    <div className='col-sm-12 col-md-6 pb-2'>                     
                          {(item.type === 'number' || item.type === 'text' || item.type === 'email' || item.type === 'url') ?
                            <Controller
                            name={item.name}
                            control={control}
                            render={({ field, fieldState }) => (
                              <DoInputField
                                input={field}
                                id={field.id}
                                name={field.name}
                                label={item.label}
                                field={field}
                                item={item}
                                type={item.type === 'number' ? "number":"text"}
                                fieldState={fieldState}
                                errors={errors}
                                placeholder={item.placeholder ? item.placeholder : null}
                              />)}
                          /> : null}



                </div>
              )) : null}
              <div className='col-sm-12 col-md-6'>
                <div className="form__form-group">
                  <span className="form__form-group-label">Profile Image</span>
                  <div className="form__form-group-field">

                    <Controller
                      name={'photo'}
                      control={control}
                      render={({ field, fieldState }) => (
                        <DoFileUplaod
                          field={field}
                          url={"uploads?uploadWhileCreate=true&uploadPath=employee"}
                          label='Upload photo'
                          acceptType="image/*"
                          showPreview={false}
                          userClickUpload={true}
                        />)}
                    />
                  </div>

                </div>
              </div>

            </div>


            <div className='d-flex mt-3 justify-content-end'>
              <Button type="button" size='sm' color='primary' label='Reset' className='me-3' onClick={() => { reset(userData) }} />
              <Button type='submit' size='sm' color='primary' label='Update' />
            </div>


          </form>
        </CardBody>
      </Card>
    </Col >
  );
}




export default Profile;