import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
import Tooltip from '@material-ui/core/Tooltip';

const ColorPickerWithTooltip = ({ field }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipTitle, setTooltipTitle] = useState('');

  const customColors = [
    '#D32F2F', '#C2185B', '#7B1FA2', '#512DA8', '#303F9F',
    '#1976D2', '#0288D1', '#0097A7', '#00796B', '#388E3C',
    '#689F38', '#AFB42B', '#F57C00', '#E64A19', '#5D4037',
    '#795548', '#004D40', '#1B5E20', '#BF360C', '#3E2723', 
    '#212121', '#000080', '#8B0000', '#006400'
];

const colorNames = {
    '#D32F2F': 'Red',
    '#C2185B': 'Pink',
    '#7B1FA2': 'Purple',
    '#512DA8': 'Dark Purple',
    '#303F9F': 'Indigo',
    '#1976D2': 'Blue',
    '#0288D1': 'Light Blue',
    '#0097A7': 'Cyan',
    '#00796B': 'Teal',
    '#388E3C': 'Green',
    '#689F38': 'Lime',
    '#AFB42B': 'Yellow',
    '#F57C00': 'Orange',
    '#E64A19': 'Deep Orange',
    '#5D4037': 'Brown',
    '#795548': 'Dark Brown',
    '#004D40': 'Deep Teal',
    '#1B5E20': 'Dark Green',
    '#BF360C': 'Deep Red',
    '#3E2723': 'Dark Sienna',
    '#212121': 'Charcoal',
    '#000080': 'Navy Blue',
    '#8B0000': 'Dark Red',
    '#006400': 'Dark Green'
};


  const handleSwatchHover = (color) => {
    const hexColor = color.hex.toUpperCase();
    const colorName = colorNames[hexColor] || hexColor;
    setTooltipTitle(colorName);
    setTooltipOpen(true);
  };

  const handleSwatchLeave = () => {
    setTooltipOpen(false);
  };

  return (
    <Tooltip title={tooltipTitle} open={tooltipOpen} placement="top">
      <div onMouseLeave={handleSwatchLeave}>
        <SketchPicker
          color={field.value}
          onChange={color => field.onChange(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`)}
          onSwatchHover={handleSwatchHover}
          presetColors={customColors}
          className='color-picker'
          {...field}
        />
      </div>
    </Tooltip>
  );
};

export default ColorPickerWithTooltip;
