import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";
// config file

const Clinics = (props) => {

  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );

  const [stateOptions, setStateOptions] = useState();


  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Clinics");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
    setStateData();
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };


  const setStateData = async () => {
    let arr = [];
    if (localStorage.StatesOptions) {
      arr = await JSON.parse(localStorage.StatesOptions);
    } else {
      let a = await fetchMethodRequest('GET', apiCalls.Settings).then(res => {
        if (res.settings[0]?.states?.length) {
          arr = res.settings[0]?.states.map(option => { return { label: option.split('-')[1].trim(), value: option, displayLabel: option } })
        }
      })
    }
    setStateOptions(arr);
  }

  const getTableFields = () => {

    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "clinicId",
        type: "uniqueID",
        placeholder: "Clinic ID",
        label: "Clinic ID",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Clinic ID",
        derivedValue: "clinicId=clinicId=clinicId=clinicId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "clinicId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "5",
        textAlign: "left",
        show: true,
        field: "clinicId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "abbrevation",
        type: "text",
        placeholder: "Clinic Name",
        label: "Clinic Name",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Clinic Name",
        derivedValue: "abbrevation=abbrevation=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "abbrevation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "abbrevation",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "hospitalId",
        type: "relateAutoComplete",
        placeholder: "Practice Name",
        label: "Practice Name",
        width: "150px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Practice Name",
        derivedValue: "hospitalID=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        searchField: "hospitalName",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "Hospital",
        searchApi: "hospitals",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "hospitalId",
        filter:true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "externalId",
        type: "uniqueID",
        placeholder: "External ID",
        label: "External ID",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "External ID",
        derivedValue: "externalId=externalId=externalId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "externalId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "5",
        textAlign: "left",
        show: true,
        field: "externalId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phone",
        type: "text",
        placeholder: "Phone Number",
        label: "Phone Number",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Phone Number",
        derivedValue: "phone=phone=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "left",
        show: true,
        field: "phone",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "emailTiming",
        type: "number",
        placeholder: "Hours",
        header: "Appointment Email send in(Hrs)",
        width: "120px",
        addFormOrder: 25,
        editFormOrder: 25,
        derivedValue: "emailTiming=undefined",
        capitalizeTableText: false,
        sortable: true,
        filter: true,
        id: "emailTiming",
        globalSearchField: "false",
        controllerId: null,
        show: true,
        field: "emailTiming",
        textAlign: "left",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  const getFormFields = () => {
    return [
      {
        name: "hospitalId",
        type: "relateAutoComplete",
        placeholder: "Practice Name",
        label: "Practice Name",
        width: "150px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "hospitalId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "false",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1009,
        searchField: "hospitalName",
        fieldType: "relateAutoComplete",
        populateValue: "hospitalID",
        populateField: "hospitalName",
        populteFields: [],
        displayFields: ["hospitalID"],
        controllerName: "Hospitals",
        searchApi: "hospitals",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "clinicId",
        type: "uniqueID",
        placeholder: "Clinic ID",
        label: "Clinic ID",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "clinicId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "clinicId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "5",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: true,
        isEditFormHidden: true,
      },
      {
        name: "abbrevation",
        type: "text",
        placeholder: "Clinic Name",
        label: "Clinic Name",
        width: "130px",
        addFormOrder: 1,
        editFormOrder: 1,
        derivedValue: "abbrevation=abbrevation=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "abbrevation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
      },

      {
        name: "isMedical",
        type: "checkbox",
        placeholder: "Is Medical",
        label: "Is Medical",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        derivedValue: "isMedical=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "isMedical",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "5",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "externalId",
        type: "uniqueID",
        placeholder: "External ID",
        label: "External ID",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        derivedValue: "externalId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "externalId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "5",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "phone",
        type: "number",
        placeholder: "Phone Number",
        label: "Phone Number",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "phone=phone=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        maxLength:config.phoneNumberLength,
        fieldType:"phone",
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "fax",
        type: "number",
        placeholder: "Fax",
        label: "Fax",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        derivedValue: "fax=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "fax",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "region",
        type: "text",
        placeholder: "Region",
        label: "Region",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        derivedValue: "region=region=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "region",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "hideFromInsurance",
        type: "checkbox",
        placeholder: "HideFrom Insurance Verification List ",
        label: "HideFrom Insurance Verification List ",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "hideFromInsurance=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hideFromInsurance",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "hideFromInsurance",
        fieldType: "checkbox",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 2,
        derivedValue: "email=email=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "email",
        fieldType: "email",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "emailAlias",
        type: "email",
        placeholder: "Email Sender Alias Override",
        label: "Email Sender Alias Override",
        width: "150px",
        addFormOrder: 11,
        editFormOrder: 11,
        derivedValue: "emailAlias=emailAlias=emailAlias=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "emailAlias",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "accountNumber",
        type: "number",
        placeholder: "Bank Account Number",
        label: "Bank Account Number",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "accountNumber=accountNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "accountNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "schedulingNote",
        type: "text",
        placeholder: "Scheduling Note",
        label: "Scheduling Note",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        derivedValue: "schedulingNote=schedulingNote=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "schedulingNote",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "isHidden",
        type: "checkbox",
        placeholder: "Is Hidden",
        label: "Is Hidden",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "isHidden=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "isHidden",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "isHidden",
        fieldType: "checkbox",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "address",
        type: "text",
        placeholder: "Address",
        label: "Address",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        derivedValue: "address=address=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "address2",
        type: "text",
        placeholder: "Address2",
        label: "Address2",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        derivedValue: "address2=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "address2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        fieldName: "address2",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "city",
        type: "text",
        placeholder: "City",
        label: "City",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        derivedValue: "city=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        customWidthClass: 'col-lg-2 col-md-2',
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "state",
        type: "dropDown",
        placeholder: "State",
        label: "State",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        derivedValue: "state=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        customWidthClass: 'col-lg-1 col-md-2',
        options: stateOptions,
        itemTemplate: true,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "zipcode",
        type: "number",
        placeholder: "Zipcode",
        label: "Zipcode",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        derivedValue: "zipcode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "zipcode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        customWidthClass: 'col-lg-1 col-md-2',
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "placeOfService",
        type: "dropDown",
        placeholder: "Default Proc Place Of Service",
        label: "Default Proc Place Of Service",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        derivedValue: "placeOfService=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "placeOfService",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Office", value: "Office", color: "primary" },
          { label: "Office1", value: "Office1", color: "primary" },
          { label: "Office2", value: "Office2", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "defaultProvider",
        type: "dropDown",
        placeholder: "Default Provider",
        label: "Default Provider",
        width: "130px",
        addFormOrder: 20,
        editFormOrder: 20,
        derivedValue: "defaultProvider=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "defaultProvider",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "NDoc1", value: "NDoc1", color: "primary" },
          { label: "NDoc1", value: "NDoc1", color: "primary" },
          { label: "NDoc1", value: "NDoc1", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "defaultBillingType",
        type: "dropDown",
        placeholder: "Default Billing Type",
        label: "Default Billing Type",
        width: "130px",
        addFormOrder: 21,
        editFormOrder: 21,
        derivedValue: "defaultBillingType=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "defaultBillingType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          {
            label: "Use Global Preference",
            value: "Use Global Preference",
            color: "primary",
          },
          {
            label: "Use Global Preference",
            value: "Use Global Preference",
            color: "primary",
          },
          {
            label: "Use Global Preference",
            value: "Use Global Preference",
            color: "primary",
          },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "autoClaims",
        type: "dropDown",
        placeholder: "Auto receive Claims with no assignment of benefits",
        label: "Auto receive Claims with no assignment of benefits",
        width: "130px",
        addFormOrder: 30,
        editFormOrder: 30,
        derivedValue: "autoClaims=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "autoClaims",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          {
            label: "Use Global Preference",
            value: "Use Global Preference",
            color: "primary",
          },
          {
            label: "Use Global Preference1",
            value: "Use Global Preference1",
            color: "primary",
          },
          {
            label: "Use Global Preference2",
            value: "Use Global Preference2",
            color: "primary",
          },
        ],
        fieldType: "dropDown",
        dependent: [],
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "specialty",
        type: "text",
        placeholder: "Specialty",
        label: "Specialty",
        width: "130px",
        addFormOrder: 22,
        editFormOrder: 22,
        derivedValue: "specialty=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "specialty",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "patientCount",
        type: "number",
        placeholder: "Patient Count",
        label: "Patient Count",
        width: "120px",
        addFormOrder: 25,
        editFormOrder: 25,
        derivedValue: "patientCount=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "patientCount",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "emailTiming",
        type: "number",
        placeholder: "Hours",
        label: "Appointment Email send in(Hrs)",
        width: "120px",
        addFormOrder: 25,
        editFormOrder: 25,
        derivedValue: "emailTiming=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "emailTiming",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "clinicChairs",
        type: "number",
        placeholder: "Clinic Chairs",
        label: "Clinic Chairs",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        derivedValue: "clinicChairs=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "clinicChairs",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "true",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name: "insuranceBilling",
        type: "radio",
        placeholder: "Default Insurance Billing Provider",
        label: "Default Insurance Billing Provider",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        derivedValue: "insuranceBilling=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "insuranceBilling",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Default Practice Provider", value: "Default Practice Provider", },
          { label: "Treating Provider", value: "Treating Provider" },
          { label: "Specific Provider", value: "Specific Provider" },
        ],
        customWidthClass: 'col-lg-8 col-md-6',
        fieldType: "radio",
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
    ];
  };

  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "left",
        globalSearchField: "true",
        show: true,
        field: "name",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.monthDateFormat,
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.monthDateFormat,
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        show: true,
        textAlign: "center",
        width: 60,
        fieldType: "Actions",
        field: "Actions",
        header: "Actions",
        label: "Actions",
        filter: false,
        sortable: false,
        displayInSettings: true,
        displayinServer: "false",
        displayinlist: "true",
        displayinaddForm: "false",
        displayineditForm: "false",
        mobile: true,
        tDisplay: true,
      },
      {
        name: "isMedical",
        type: "uniqueID",
        placeholder: "Is Medical",
        label: "Is Medical",
        width: "120px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Is Medical",
        derivedValue: "isMedical=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "isMedical",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "5",
        textAlign: "Center",
        show: true,
        field: "isMedical",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "clinicId",
        type: "uniqueID",
        placeholder: "Clinic ID",
        label: "Clinic ID",
        width: "120px",
        addFormOrder: 2,
        editFormOrder: 2,
        header: "Clinic ID",
        derivedValue: "clinicId=clinicId=clinicId=clinicId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: true,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "clinicId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "5",
        textAlign: "Center",
        show: true,
        field: "clinicId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "externalId",
        type: "uniqueID",
        placeholder: "External ID",
        label: "External ID",
        width: "120px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "External ID",
        derivedValue: "externalId=externalId=externalId=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "externalId",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        uniqueIdLength: "5",
        textAlign: "Center",
        show: true,
        field: "externalId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "abbrevation",
        type: "text",
        placeholder: "Abbrevation",
        label: "Abbrevation",
        width: "130px",
        addFormOrder: 4,
        editFormOrder: 4,
        header: "Abbrevation",
        derivedValue: "abbrevation=abbrevation=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "abbrevation",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "abbrevation",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        fieldType: "Link",
        style: {
          color: "#0e4768",
          cursor: "pointer",
          textTransform: "capitalize",
        },
        tDisplay: true,
      },
      {
        name: "description",
        type: "text",
        placeholder: "Description",
        label: "Description",
        width: "130px",
        addFormOrder: 5,
        editFormOrder: 5,
        header: "Description",
        derivedValue: "description=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "description",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "description",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "phone",
        type: "number",
        placeholder: "Phone Number",
        label: "Phone Number",
        width: "120px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Phone Number",
        derivedValue: "phone=phone=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "phone",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "phone",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "fax",
        type: "number",
        placeholder: "Fax",
        label: "Fax",
        width: "120px",
        addFormOrder: 7,
        editFormOrder: 7,
        header: "Fax",
        derivedValue: "fax=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "fax",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "fax",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "region",
        type: "text",
        placeholder: "Region",
        label: "Region",
        width: "130px",
        addFormOrder: 8,
        editFormOrder: 8,
        header: "Region",
        derivedValue: "region=region=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "region",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "region",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "hideFromInsurance",
        type: "checkbox",
        placeholder: "HideFrom Insurance Verification List ",
        label: "HideFrom Insurance Verification List ",
        width: "120px",
        addFormOrder: 9,
        editFormOrder: 9,
        header: "HideFrom Insurance Verification List ",
        derivedValue: "hideFromInsurance=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "hideFromInsurance",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "hideFromInsurance",
        showOrHideFields: [],
        fieldName: "hideFromInsurance",
        fieldType: "checkbox",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "email",
        type: "email",
        placeholder: "Email",
        label: "Email",
        width: "150px",
        addFormOrder: 10,
        editFormOrder: 2,
        header: "Email",
        derivedValue: "email=email=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "email",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "email",
        showOrHideFields: [],
        fieldName: "email",
        fieldType: "email",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "emailAlias",
        type: "email",
        placeholder: "Email Sender Alias Override",
        label: "Email Sender Alias Override",
        width: "150px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Email Sender Alias Override",
        derivedValue: "emailAlias=emailAlias=emailAlias=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "emailAlias",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "emailAlias",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "accountNumber",
        type: "number",
        placeholder: "Bank Account Number",
        label: "Bank Account Number",
        width: "120px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Bank Account Number",
        derivedValue: "accountNumber=accountNumber=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "accountNumber",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "accountNumber",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "schedulingNote",
        type: "text",
        placeholder: "Scheduling Note",
        label: "Scheduling Note",
        width: "130px",
        addFormOrder: 13,
        editFormOrder: 13,
        header: "Scheduling Note",
        derivedValue: "schedulingNote=schedulingNote=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "schedulingNote",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "schedulingNote",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "isHidden",
        type: "checkbox",
        placeholder: "Is Hidden",
        label: "Is Hidden",
        width: "120px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Is Hidden",
        derivedValue: "isHidden=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "isHidden",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "isHidden",
        showOrHideFields: [],
        fieldName: "isHidden",
        fieldType: "checkbox",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address",
        type: "text",
        placeholder: "Address",
        label: "Address",
        width: "130px",
        addFormOrder: 14,
        editFormOrder: 14,
        header: "Address",
        derivedValue: "address=address=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "address",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "address2",
        type: "text",
        placeholder: "Address2",
        label: "Address2",
        width: "130px",
        addFormOrder: 15,
        editFormOrder: 15,
        header: "Address2",
        derivedValue: "address2=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "address2",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "address2",
        showOrHideFields: [],
        fieldName: "address2",
        fieldType: "text",
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "city",
        type: "text",
        placeholder: "City",
        label: "City",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "City",
        derivedValue: "city=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "city",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "city",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "state",
        type: "text",
        placeholder: "State",
        label: "State",
        width: "130px",
        addFormOrder: 16,
        editFormOrder: 16,
        header: "State",
        derivedValue: "state=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: true,
        actions: [],
        actionsNumber: [],
        id: "state",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "state",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "zipcode",
        type: "number",
        placeholder: "Zipcode",
        label: "Zipcode",
        width: "120px",
        addFormOrder: 17,
        editFormOrder: 17,
        header: "Zipcode",
        derivedValue: "zipcode=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "zipcode",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "zipcode",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "insuranceBilling",
        type: "radio",
        placeholder: "Default Insurance Billing Provider",
        label: "Default Insurance Billing Provider",
        width: "120px",
        addFormOrder: 18,
        editFormOrder: 18,
        header: "Default Insurance Billing Provider",
        derivedValue: "insuranceBilling=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "insuranceBilling",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          {
            label: "Default Practice Provider",
            value: "Default Practice Provider",
          },
          { label: "Treating Provider", value: "Treating Provider" },
          { label: "Specific Provider", value: "Specific Provider" },
        ],
        fieldType: "radio",
        textAlign: "Center",
        show: true,
        field: "insuranceBilling",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "placeOfService",
        type: "dropDown",
        placeholder: "Default Proc Place Of Service",
        label: "Default Proc Place Of Service",
        width: "130px",
        addFormOrder: 19,
        editFormOrder: 19,
        header: "Default Proc Place Of Service",
        derivedValue: "placeOfService=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "placeOfService",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "Office", value: "Office", color: "primary" },
          { label: "Office1", value: "Office1", color: "primary" },
          { label: "Office2", value: "Office2", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "placeOfService",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "defaultProvider",
        type: "dropDown",
        placeholder: "Default Provider",
        label: "Default Provider",
        width: "130px",
        addFormOrder: 20,
        editFormOrder: 20,
        header: "Default Provider",
        derivedValue: "defaultProvider=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "defaultProvider",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          { label: "NDoc1", value: "NDoc1", color: "primary" },
          { label: "NDoc1", value: "NDoc1", color: "primary" },
          { label: "NDoc1", value: "NDoc1", color: "primary" },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "defaultProvider",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "defaultBillingType",
        type: "dropDown",
        placeholder: "Default Billing Type",
        label: "Default Billing Type",
        width: "130px",
        addFormOrder: 21,
        editFormOrder: 21,
        header: "Default Billing Type",
        derivedValue: "defaultBillingType=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "defaultBillingType",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          {
            label: "Use Global Preference",
            value: "Use Global Preference",
            color: "primary",
          },
          {
            label: "Use Global Preference",
            value: "Use Global Preference",
            color: "primary",
          },
          {
            label: "Use Global Preference",
            value: "Use Global Preference",
            color: "primary",
          },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "defaultBillingType",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "autoClaims",
        type: "dropDown",
        placeholder: "Auto receive Claims with no assignment of benefits",
        label: "Auto receive Claims with no assignment of benefits",
        width: "130px",
        addFormOrder: 30,
        editFormOrder: 30,
        header: "Auto receive Claims with no assignment of benefits",
        derivedValue: "autoClaims=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "autoClaims",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        options: [
          {
            label: "Use Global Preference",
            value: "Use Global Preference",
            color: "primary",
          },
          {
            label: "Use Global Preference1",
            value: "Use Global Preference1",
            color: "primary",
          },
          {
            label: "Use Global Preference2",
            value: "Use Global Preference2",
            color: "primary",
          },
        ],
        fieldType: "dropDown",
        dependent: [],
        textAlign: "Center",
        show: true,
        field: "autoClaims",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "specialty",
        type: "text",
        placeholder: "Specialty",
        label: "Specialty",
        width: "130px",
        addFormOrder: 22,
        editFormOrder: 22,
        header: "Specialty",
        derivedValue: "specialty=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "specialty",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "specialty",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
      {
        name: "patientCount",
        type: "number",
        placeholder: "Patient Count",
        label: "Patient Count",
        width: "120px",
        addFormOrder: 25,
        editFormOrder: 25,
        header: "Patient Count",
        derivedValue: "patientCount=undefined",
        capitalizeTableText: false,
        sortable: false,
        isClickable: false,
        filter: false,
        actions: [],
        actionsNumber: [],
        id: "patientCount",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "patientCount",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        tDisplay: true,
      },
    ];
    return data;
  };

  return (
    <span>
      {stateOptions &&
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          //
          addRequired={rolePermission && rolePermission == "Edit" ? true : false}
          editRequired={rolePermission && rolePermission == "Edit" ? true : false}
          deleteRequired={rolePermission && rolePermission == "Edit" ? true : false}
          viewRequired={rolePermission && rolePermission == "Edit" ? true : false}
          exportRequired={rolePermission && rolePermission == "Edit" ? true : false}
          sample={true}
          PrintRequired={rolePermission && rolePermission == "Edit" ? true : false}
          printRequried={true}
          actionsTypes={[
            {
              name: "Delete",
              options: [
                {
                  label: "Delete",
                  value: "Delete",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: true,
                },
              ],
            },
          ]}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          sampleFilePath={filePath.clinics}
          globalSearch={"Clinic ID/Abbrevation/Phone"}
          displayName="Clinics"
          type="Clinics"
          routeTo={apiCalls.clinics}
          displayViewOfForm="screen"
          sideFormLeftOrRight=""
          apiResponseKey={apiCalls.clinics.toLowerCase()}
          apiUrl={apiCalls.clinics}
          selectedId={params.id}
        />
      }

    </span>
  );
};

export default Clinics;
