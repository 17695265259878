import React, { useEffect, useState, useRef } from 'react';

import DoAutoCompleteField from '../../Form/Fields/DoAutoCompleteField';
import fetchMethodRequest from '../../../config/service';
import { useForm, Controller } from 'react-hook-form';
import apiCalls from '../../../config/apiCalls';

export default function PatientBar() {
   const [hspData,setHspData] = useState(localStorage.ClinicData ? JSON.parse(localStorage.getItem('HospitalData')) : null);    


    let { handleSubmit, register, reset, setValue, getValues, formState: { errors }, control, } = useForm();

    useEffect(() => {

        if (hspData && hspData._id) {
            setValue('hospitalId', hspData);
        }

    }, []);



    const onClickRemoveButton = (item) => {
        localStorage.removeItem(item.localStorageField);
        if (item.name == 'hospitalId') {
            setHspData({});
        }
        setValue(item.name, null);

    }

    const onChange = (value, item) => {
        if(value){
            localStorage.setItem(item.localStorageField, JSON.stringify(value));

        }else{
            localStorage.removeItem(item.localStorageField);
        }
        window.dispatchEvent(new CustomEvent('HospitalBar'));
    }

    const setPatientDefaultData = (data) => {
        let patientUrl = apiCalls.patients
        let filterCriteria = { limit: 20, page: 1, criteria: [{ key: 'clinicId', value: data._id, type: 'eq' }], direction: 'desc', softfield: 'created' };
        fetchMethodRequest('GET', `${patientUrl}?filter=${JSON.stringify(filterCriteria)}`).then((response) => {
            if (response[patientUrl] && response[patientUrl].length == 1) {
                setValue('patientId', response[patientUrl][0]);
                onChange(response[patientUrl][0], { localStorageField: 'PatientData', name: 'patientId' })
            } else {
                setValue('patientId', null);
                onChange(null, { localStorageField: 'PatientData', name: 'patientId' })
            }
        });
    }


    /**
     * 
     * @param {Object} item 
     * @returns 
     */
    let getAutoComplete = (item) => {
        let itemTemplateFunction;

        if (item.populateValue && item.populateField) {
            itemTemplateFunction = (option) => {
                const value = option[item.populateValue];
                const label = option[item.populateField];
                return (
                    <div className="autocomplete-item">
                        <div>{`${value} - ${label}`}</div>
                    </div>
                );
            };
        }
        return (
            <div className='patient-auto-complete col-2 px-2 d-flex'>
                <Controller
                    name={item.name}
                    control={control}
                    render={({ field, fieldState }) => (
                        <DoAutoCompleteField
                            input={field}
                            id={field.id}
                            name={field.name}
                            field={field}
                            item={item}
                            fieldState={fieldState}
                            errors={errors}
                            screen={'PatientNavBar'}
                            searchApi={item.searchApi}
                            itemTemplate={itemTemplateFunction}
                            searchField={item.searchField}
                            filterFieldType={item.filterFieldType ? item.filterFieldType : null}
                            placeholder={item.placeholder}
                            label={item.label}
                            onChange={(value) => onChange(value, item)}
                            populateValue={item.populateValue}
                            populateField={item.populateField}
                            getValues={getValues}
                        />)}
                />
            </div>
        )
    }


    return (
        <div className='patient-nav-bar-main'>
            {getAutoComplete({ name: 'hospitalId', populateValue: 'hospitalID', populateField: 'hospitalName', searchApi: apiCalls.hospitals, searchField: 'hospitalName', placeholder: 'Select a Practice ID - Name', disableLabel: true, style: { height: '1.9rem', lineHeight: '1.9rem' }, localStorageField: 'HospitalData', value: hspData, clearable: true ,localStorageField: 'HospitalData'})}
        </div>

    );
}
