const apiCalls = {
  loginUser: "auth/login",
  logoutUser: "auth/logout",
  loginChangePassword: "auth/loginChangePassword",
  MenuList: "menus",
  Settings: "settings",
  LocationImagePath: "uploads?uploadWhileCreate=true&uploadPath=location",
  ProfileImagePath: "uploads?uploadWhileCreate=true&uploadPath=employee",
  dashboard: "dashboard/counts",
  Uploads: "bulkuploadStatus",
  employee: "employees",
  templates: "templates",
  employees: "employees",
  users: "users",
  hospitals: "hospitals",
  clinics: "clinics",
  patients: "patients",
  forms: "forms",
  schedules: "schedules",
  chairs: "chairs",
  appointments: "appointments",
  questions: "questions",
  slots: "slots",
  procedures: "procedures",
  txPlans: "txPlans",
  treatments: "treatments",
  slotcancel: "appointments/slotcancel",
  slotupdate: "appointments/newslotupdate",
  procedures: 'procedures',
  treatmentPlan: 'treatmentplan',
  txPlan: 'txPlan',
  dragAndDrop: 'appointments/dragAndDrop',
  resizing: 'appointments/resizing',
  drugs: "drugs",
  prescriptions: "prescriptions",
  pharmacies: "pharmacies",
  treatmentPlans: "treatmentPlans",
  reasons: "reasons",
  insurances: "insurances",
  feeSchedules: 'feeSchedules',
  releaseNotes: 'releaseNotes',
};

// {"loginUser":"auth/login","logoutUser":"auth/logout","loginChangePassword":"auth/loginChangePassword","MenuList":"menus","Settings":"settings","LocationImagePath":"uploads?uploadWhileCreate=true&uploadPath=location","ProfileImagePath":"uploads?uploadWhileCreate=true&uploadPath=employee","dashboard":"dashboard/counts","employee":"employees","employees":"employees","Uploads":"bulkuploadStatus"}

export default apiCalls;
