import React, { useEffect, useState } from "react";
import { AutoComplete } from 'primereact/autocomplete';
import fetch from '../../../config/service';
import config from "../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from '@fortawesome/free-solid-svg-icons';

const DoAutoCompleteField = (props) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [selectedDisplayValue, setSelectedDisplayValue] = useState("");
    const [isValidate, setIsValidate] = useState(false);
    const [noData, setNoData] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [disable, setDisable] = useState(props.disabled || props?.item?.disabled ? true : false);
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const name = props.name;
    const type = props.type ? props.type : 'text';
    let field = props.field;
    const placeholder = props.placeholder;
    const item = props.item ? props.item : {};

    // useEffect(()=>{
    //     getAllSuggestions();
    // },[])
    useEffect(() => {
        setPatientData();
    }, []);

    useEffect(() => {//Render this function when we change the Data
        setSearchFieldValueOnTheBody(field.value);
        // if (!field.value) {
        //     onSelectValue({ value: null });
        // }
    }, [field.value])

    useEffect(() => {
        if (props.item.hasDependencyField) {
            field.onChange(null);
        }
    }, [props.dependentFieldValue]);

    const setPatientData = async () => {
        if (props.formType === "add" && props.name === 'patientId') {
            await getAllSuggestions();

            const storedPatientDetails = JSON.parse(localStorage.getItem('PatientData'));
            if (storedPatientDetails) {
                if (props.name === 'patientId') {
                    setDisable(!!storedPatientDetails); // Disable only if patient details are present
                }
                const setDisplayValueFromStoredDetails = (details) => {
                    if (props.populateValue && props.populateField && details && details[props.populateValue] && details[props.populateField]) {
                        const formattedDisplayValue = `${details[props.populateValue]} (${details[props.populateField]})`;
                        setSelectedDisplayValue(formattedDisplayValue);
                    } else {
                        setSelectedDisplayValue("");
                    }
                };

                if (props.name === 'patientId' && storedPatientDetails.patientId) {
                    field.onChange(storedPatientDetails);
                    onSelectValue({ value: storedPatientDetails });
                    setDisplayValueFromStoredDetails(storedPatientDetails);

                    if (props.screen === 'Appointments') {
                        props.onPatientSelect(storedPatientDetails);
                    }
                }

                if (props.screen === 'Forms' && props.name === 'hospitalId' && storedPatientDetails.hospitalId?.hospitalID) {
                    const hospitalIdValue = storedPatientDetails.hospitalId.hospitalID;
                    field.onChange(storedPatientDetails.hospitalId);
                    onSelectValue({ value: storedPatientDetails.hospitalId.hospitalID });
                    setDisplayValueFromStoredDetails(storedPatientDetails.hospitalId);
                }
            }
        }
    };
    useEffect(() => {
        const setDisplayValueFromStoredDetails = (storedDetails) => {
            if (props.populateValue && props.populateField && storedDetails && storedDetails[props.populateValue] && storedDetails[props.populateField]) {
                const formattedDisplayValue = `${storedDetails[props.populateValue]} (${storedDetails[props.populateField]})`;
                setSelectedDisplayValue(formattedDisplayValue);
            }
        };

        if (props.formType === "add") {
            const storedPatientDetails = JSON.parse(localStorage.getItem('PatientData'));
            if (storedPatientDetails) {
                if (props.name === 'patientId' && storedPatientDetails.patientId) {
                    field.onChange(storedPatientDetails);
                    onSelectValue({ value: storedPatientDetails });
                    setDisplayValueFromStoredDetails(storedPatientDetails);

                    if (props.screen === 'Appointments') {
                        props.onPatientSelect(storedPatientDetails);
                    }
                }

                // Handle hospitalId for the 'Forms' screen with similar logic
                if (props.screen === 'Forms' && props.name === 'hospitalId' && storedPatientDetails.hospitalId?.hospitalID) {
                    const hospitalIdValue = storedPatientDetails.hospitalId.hospitalID;
                    field.onChange(storedPatientDetails);
                    onSelectValue({ value: storedPatientDetails.hospitalId });
                    setDisplayValueFromStoredDetails(storedPatientDetails);
                }
            }
        }
    }, [localStorage.getItem('PatientData')]);

    const onSelectRecord = (e) => {
        setNoData(false);
        setIsValidate(false);
        setErrorMessage('');
        if (props.input) {
            const { name, onChange } = this.props.input;
            onChange(e.value);
            this.props.handleAutoCompleteData(e.value, name)
            if (e && e.value) {
                this.removeDuplicates(e.value, name);
            }
        }
    }

    const onSelectValue = (e) => {
        if (!item.isMultiple) {
            field.onChange(e.value);
        }

        if (props.onChange) {
            props.onChange(e.value)
        }
    }

    useEffect(() => {//when we change the clinic on the patient Nav bar the function is render
        if (props.formType === 'add' && item.name === 'hospitalId') {
            const data = JSON.parse(localStorage.getItem('HospitalData'));
            setDisable(data ? true : false);
            setTimeout(() => { onSelectValue({ value: data }) }, 100)
        }
    }, [localStorage.getItem('HospitalData')]);



    const clearData = () => {//when we need to clear the data on the field
        if (!item.disabled) {
            onSelectValue({ value: undefined });
        }
    }

    // to remove duplicates
    const removeDuplicates = () => {
        if (this.state.filteredSuggestions) {
            setSuggestions(this.state.filteredSuggestions);
        }
    }

    /**
     * 
     * @param {Object} value 
     */
    const setSearchFieldValueOnTheBody = (value) => {//Set the Search field value on the Body due to filter data by the search field on the DataTable Filter
        if (item.searchField && props.setValue && value && value[item.searchField]) {
            props.setValue(item.name + item.searchField, value[item.searchField]);
            if (item.tableFilterKeys && item.tableFilterKeys.length > 0) {
                item.tableFilterKeys.map((key) => {
                    if (value[key]) {
                        props.setValue(item.name + key, value[key]);
                    }
                })
            }
        }
    }

    const getAllSuggestions = (event, type) => {
        let url;
        // this.setState({
        //     isValidate: false,
        //     noData: false,
        //     errorMessage: ''
        // });
        setIsValidate(false);
        setNoData(false);
        setErrorMessage('');
        let filterCriteria = {
            limit: 30,
            page: 1,
            sortfield: "created",
            direction: "desc",
            criteria: []
        };

        if (event && event.query) {
            if (item.searchPatternMatch && !item.searchPatternMatch?.test(event.query)) {//searchPatternMatch it should Regular expresetion
                return;
            }

            if (item?.searchGlobally) {
                filterCriteria["globalSearch"] = { "value": event.query, "type": "user" };
            } else {
                filterCriteria['criteria'].push({
                    "key": props.searchField,
                    "value": event.query,
                    "type": "regexOr"
                });
            }
        };


        if (props.item.hasDependencyField && props.getValues && props.getValues(props.item.hasDependencyField) && props.getValues(props.item.hasDependencyField)["_id"]) {
            let value;
            if (item.hasDependencyFieldType === 'in') {
                value = [props.getValues(props.item.hasDependencyField)["_id"]];
            } else {
                value = props.getValues(props.item.hasDependencyField)["_id"];
            }
            filterCriteria['criteria'].push({
                "key": props.item.fieldForKey,
                "value": value,
                "type": item.hasDependencyFieldType ? item.hasDependencyFieldType : 'eq',
            })
        }

        if(item.defaultFilterCriteria && item.defaultFilterCriteria.length>0){
            filterCriteria['criteria']=[...filterCriteria['criteria'],...item.defaultFilterCriteria];
        }

        let apiUrl = props.searchApi;
        url = `${apiUrl}?searchFrom=autoComplete&filter=${JSON.stringify(filterCriteria)}`;
        return fetch('GET', url)
            .then((response) => {
                if (response) {
                    let dropdownData = response[apiUrl] || [];
                    if (dropdownData.length === 0) {
                        setNoData(true);
                    }
                    setFilteredSuggestions(dropdownData);
                }
            }).catch((err) => {
                return err;
            });
    }

    const setSuggestions = async (dropdownData) => {

        // if (props.screen === 'Employees' && props.name === "provider") {
        //     dropdownData = dropdownData.filter(item => item.role === 'Doctor');
        // }
        // else if (props.screen === 'Appointments' && props.name === "handler") {
        //     dropdownData = dropdownData.filter(item => item.role === 'Operative');
        // } else if (props.screen === 'Appointments' && props.name === "provider") {
        //     dropdownData = dropdownData.filter(item => item.role === 'Doctor');
        // }

        if (props.input && props.input.value) {
            if (props.input.value.length > 0) {
                let values = props.input.value;
                if (Array.isArray(values)) {
                    values.forEach(element => {
                        let field = '_id';
                        dropdownData = dropdownData.filter((item) => item[field] !== element[field]);
                    });
                }
            }
        }
        setFilteredSuggestions(dropdownData);
    }

    let markRequired = () => {
        return (
            <FontAwesomeIcon
                color='red'
                icon={faAsterisk}
                style={{ width: '7px', marginBottom: "5px", marginLeft: "2px" }}
            />
        );
    };

    return (
        <div className="flex flex-column">

            {!item.disableLabel &&
                <div className="flex align-center"> {/* Added a wrapper div with flex class */}
                    <label htmlFor={id} className="text-capitalize">{label}</label>
                    {props.markReq && props.markReq === true && markRequired()}
                </div>}
            <div className="d-flex align-items-center">
                <AutoComplete
                    className="w-100"
                    inputId={field.name}
                    value={field.value && field.value[item?.populateValue] && field.value[item?.populateField] ? `${field.value[item?.populateValue]} (${field.value[item?.populateField]})` : field.value}
                    onChange={field.onChange}
                    // inputRef={field.ref}
                    suggestions={filteredSuggestions}
                    completeMethod={getAllSuggestions}
                    placeholder={placeholder}
                    field={props.searchField}
                    dropdown={true}
                    multiple={props.multiple}
                    itemTemplate={props.itemTemplate}
                    selectionLimit={props.selectionLimit ? props.selectionLimit : config.selectionLimit}
                    onSelect={(e) => onSelectValue(e)}
                    disabled={disable}
                    style={item.style ? item.style : {}}
                    showEmptyMessage={true}
                    emptyMessage='No Records'
                />
                {item.clearable && field.value && <FontAwesomeIcon
                    className=''
                    color={field.value ? 'red' : 'grey'}
                    icon={faTimes}
                    size='lg'
                    data-toggle="tool-tip"
                    onClick={clearData}
                    style={{ width: '1.5rem' }}
                />}
            </div>

            <small className="text-danger ">{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>
        </div>
    )

}

export default DoAutoCompleteField;