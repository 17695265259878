import React, { useState, useRef, useEffect } from "react";
import SignatureCanvas from "react-signature-canvas";


export default function Sigature(props) {

    const signatureRef = useRef(null);

    useEffect(() => {
        if (!props.disabled) {
            setSignatureFun(props.value);
        }
    }, []);

    const handleSignatureChange = (e) => {
        const signatureData = signatureRef.current.toDataURL('image/png');
        props.onChange(signatureData)
    };

    const setSignatureFun = (e) => {
        //     if (e) {
        //         signatureRef.current.fromDataURL(e);
        //     }
        //     else {
        //         signatureRef.current.clear();
        //     }
        props.onChange(e ? e : "")
        e ? signatureRef.current.fromDataURL(e) : signatureRef.current.clear()
    };

    return <div>

        <div className="d-flex justify-content-between">
            <label>{props.item.label ? props.item.label : props.item.name}</label>
            {!props.disabled && <i className="pi pi-sync ml-5" onClick={() => setSignatureFun()}></i>}
        </div>
        {!props.disabled ?
            <div style={{ border: '2px solid black', display: 'inline-block' }}>
                <SignatureCanvas
                    ref={signatureRef}
                    penColor="blue"
                    canvasProps={{ width: 400, height: 150 }}
                    onEnd={handleSignatureChange}
                    disabled={props.disabled}
                    onBegin={handleSignatureChange}
                // backgroundColor="yellow"
                // clearOnResize={true}
                // throttle={30}
                />
            </div>
            : <div style={{ backgroundColor: props.disabled ? '#dedede' : 'white', width: 400, height: 160, border: '2px solid black', display: 'inline-block' }}>{props.value && <img class="mb-5 w-100 h-100" src={props.value} />}</div>}

    </div>

}