import React,{ useEffect, useState } from 'react';
import DataTable from '../../CommonDataTable/DataTable';
import { useParams } from 'react-router';
import apiCalls from '../../../../config/apiCalls'
import showToasterMessage from '../../../UI/ToasterMessage/toasterMessage';

import fetchMethodRequest from "../../../../config/service"
import RolePermissions from '../../CommonModals/Permissions';
// config file
import config from '../../../../config/config';

const Templates = (props) => {

  
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(window.location.href.includes("edit"));
  const [addSelectedRecord, setAddSelectedRecord] = useState(window.location.href.includes("create"));
  const [viewSelectedRecord, setViewSelectedRecord] = useState(window.location.href.includes("view"));
  const params = useParams();

  useEffect(()=> {
		let screenPermissions = RolePermissions.screenPermissions('Templates');
		if (screenPermissions) {		
			setRolePermission(screenPermissions);
		}

	},[]);

  const setData = (props) => {
    let rowData = {}
 
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id)
      return rowData
    }
  }
  const getTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search",
        displayInSettings: true,
      },
      {
        "show": true,
        "textAlign": "left",
        "width": 80,
        "fieldType": "Link",
        "field": "name",
        "header": "Name",
        "filter": true,
        "sortable": true,
        displayInSettings: true,
      },
      {
        name: "hospitalId",
        type: "relateAutoComplete",
        placeholder: "Practice ID",
        label: "Practice ID",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Practice ID",
        derivedValue: "hospitalId=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        populateValue:"hospitalID",
        populateField:"hospitalName",
        searchField: "hospitalID",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "Hospital",
        searchApi: apiCalls.hospitals,
        isMultiple: false,
        textAlign: "Center",
        show: true,
        filter:false,
        field: "hospitalId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
     
      {"name":"created","type":"date","placeholder":"Created","label":"Created","width":90,"header":"Created","derivedValue":"created=undefined","actions":[],"actionsNumber":[],"id":"created","displayinaddForm":"false","displayineditForm":"false","displayinlist":"true","globalSearchField":"false","controllerId":null,"fieldType":"Date","dateFormat":config.monthDateFormat,"textAlign":"Center","disabled":true,"show":true,"field":"created","addFormOrder":6,"editFormOrder":6,"mobile":true,"displayInSettings":true},{"name":"updated","type":"date","placeholder":"Updated","label":"Updated","width":90,"header":"Updated","derivedValue":"updated=undefined","actions":[],"actionsNumber":[],"id":"updated","displayinaddForm":"false","displayineditForm":"false","displayinlist":"true","globalSearchField":"false","controllerId":null,"fieldType":"Date","dateFormat":config.monthDateFormat,"textAlign":"Center","disabled":true,"show":true,"field":"updated","addFormOrder":7,"editFormOrder":7,"mobile":true,
      "displayInSettings":true},
    ];
    data.push( {
      "show": true,
      "textAlign": "center",
      "width": 60,
      "fieldType": "Actions",
      "field": "Actions",
      "header": "Actions",
      "filter": false,
      "sortable": false,
      displayInSettings: true,
    })
    return data;
  };

  /**
   * 
   * @param {Object} rowData 
   * @param {Object} col 
   * @returns Array
   */
  const custonActionIcon = (rowData, col) => {
    return rowData?.defaultTemplate ? [] : ['edit', 'delete'];
  }

  const getFormFields = () => {
    let statusTypes = [
      {
        label: 'Active',
        value: 'Active'
      },
      {
        label: 'Inactive',
        value: 'Inactive'
      },
      {
        label: 'Pending',
        value: 'Pending'
      },
    ];
    return ([
      {
        required: true,
        value: '',
        type: 'text',
        name: 'name',
        label: 'Name',
        id: 'name',
        placeholder: 'Name'
      },
      {
        name: "hospitalId",
        type: "relateAutoComplete",
        placeholder: "Practice Id",
        label: "Practice ID",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        derivedValue: "hospitalName=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        populateValue:"hospitalID",
        populateField:"hospitalName",
        searchField: "hospitalID",
        fieldType: "relateAutoComplete",
        populteFields: [],
        displayFields: [],
        controllerName: "Hospital",
        searchApi: "hospitals",
        isMultiple: false,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        // required: true,
        value: false,
        // defaultValue: false,
        type: 'radio',
        options: [{label: "Yes",value: true},{label: "No",value:false}],
        name: 'signatureRequired',
        label: 'Signature Required',
        id: 'signatureRequired',
        placeholder: 'Signature Required'
      },

      {
        required: true,
        value: '',
        type: 'ckeditor',
        name: 'templateText',
        label: 'Email Template',
        id: 'emailTemplate',
        placeholder: 'Create Template here'
      },
      {"name":"created","type":"date","placeholder":"Created","label":"Created","width":90,"derivedValue":"created=undefined","actions":[],"actionsNumber":[],"id":"created","displayinaddForm":"false","displayineditForm":"false","displayinlist":"true","globalSearchField":"false","controllerId":null,"fieldType":"Date","dateFormat":"YYYY-MM-DD HH:mm:ss","disabled":true,"show":true,"addFormOrder":6,"editFormOrder":6,"mobile":true,"displayInSettings":true,"isAddFormHidden":true,"isEditFormHidden":true},{"name":"updated","type":"date","placeholder":"Updated","label":"Updated","width":90,"derivedValue":"updated=undefined","actions":[],"actionsNumber":[],"id":"updated","displayinaddForm":"false","displayineditForm":"false","displayinlist":"true","globalSearchField":"false","controllerId":null,"fieldType":"Date","dateFormat":"YYYY-MM-DD HH:mm:ss","disabled":true,"show":true,"addFormOrder":7,"editFormOrder":7,"mobile":true,"displayInSettings":true,"isAddFormHidden":true,
      "isEditFormHidden":true},
    ]);
  }
  const getMobileTableFields = () => {
    let statusTypes = [
      {
        label: 'Active',
        value: 'Active'
      },
      {
        label: 'Inactive',
        value: 'Inactive'
      },
      {
        label: 'Pending',
        value: 'Pending'
      },
    ];
    return ([
      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search"
      },
      {
        required: true,
        value: '',
        type: 'text',
        name: 'name',
        label: 'Name',
        id: 'name',
        placeholder: 'Name'
      },
      {
        required: true,
        value: '',
        type: 'text',
        name: 'subject',
        label: 'Subject',
        id: 'subject',
        placeholder: 'Subject'
      },
      {
        required: true,
        value: '',
        type: 'ckeditor',
        name: 'templateText',
        label: 'Email Template',
        id: 'emailTemplate',
        placeholder: 'create template here'
      },
      {"name":"created","type":"date","placeholder":"Created","label":"Created","width":90,"header":"Created","derivedValue":"created=undefined","actions":[],"actionsNumber":[],"id":"created","displayinaddForm":"false","displayineditForm":"false","displayinlist":"true","globalSearchField":"false","controllerId":null,"fieldType":"Date","dateFormat":"YYYY-MM-DD HH:mm:ss","textAlign":"Center","disabled":true,"show":true,"field":"created","addFormOrder":6,"editFormOrder":6,"mobile":true,"displayInSettings":true},{"name":"updated","type":"date","placeholder":"Updated","label":"Updated","width":90,"header":"Updated","derivedValue":"updated=undefined","actions":[],"actionsNumber":[],"id":"updated","displayinaddForm":"false","displayineditForm":"false","displayinlist":"true","globalSearchField":"false","controllerId":null,"fieldType":"Date","dateFormat":"YYYY-MM-DD HH:mm:ss","textAlign":"Center","disabled":true,"show":true,"field":"updated","addFormOrder":7,"editFormOrder":7,"mobile":true,
      "displayInSettings":true},
    ]);
  };
 const saveDataToServer = async (item, field, value) => {
    let body = item[0]
    body[field] = value
    let userBody = Object.assign({}, body);
   
    if (body) {
      let method, apiUrl;
      method = 'PUT';
      apiUrl = `templates/${body._id}`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          // let sessionexpired = await localStorage.getItem('sessionexpired')
          // if (sessionexpired === "true") {
          //   await this.setState({ sessionExpiryModal: true })
          // }
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, 'success');

          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, 'error');
          }
         
        }).catch((err) => {
          return err;
        });
    } else {
      return;
    }
  }
  
    return (
      <div>
        <DataTable
          {...props}
          MobileTableFields={getMobileTableFields}
          getTableFields={getTableFields}
          formFields={getFormFields}
          actionsTypes={[{
            'name': 'Delete',
            "options": [
              { 'label': 'Delete', 'value': 'Delete', 'show': rolePermission && rolePermission == "Edit" ? true : false, "multiple": true, },
            ]
          },
          ]}
          exportRequired={true}
          printRequired={false}
          addRequired={rolePermission && rolePermission == "Edit" ? true : false}
          editRequired={rolePermission && rolePermission == "Edit" ? true : false}
          deleteRequired={rolePermission && rolePermission == "Edit" ? true : false}
          viewRequired={true}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={false}
          sample={false}
          copyRequired={true}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          dateSearchRequired={undefined}
          searchInDateRangeField={"undefined"}
          preview={false}
          globalSearchFieldName='subject'
          globalSearch={"Name"}
          type='Templates'
          apiResponseKey='templates'
          apiUrl={"templates"}
          routeTo='templates'
          displayViewOfForm='screen'
          selectedId={params.id}
          actionDependentFunction={custonActionIcon}
        />
      </div>
    );
  
}

export default Templates;
