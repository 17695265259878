import React from "react";
import { InputTextarea } from 'primereact/inputtextarea';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";

const DoTextareaField = (props) => {
    const id = props.id ? props.id : props.name;
    const label = props.label ? props.label : props.name;
    const name = props.name;
    const rows = props.rows;
    const cols = props.cols;
    let field = props.field;
    const placeholder = props.placeholder;
    const item = props.item ? props.item : {};
    
    let markRequired = () => {
        return (
          <FontAwesomeIcon
            color='red'
            icon={faAsterisk}
            style={{ width: '7px',marginBottom:"5px",marginLeft:"2px" }}
          />
        );
      };

    return (
        <div className="flex flex-column">
        <div className="flex align-center"> {/* Added a wrapper div with flex class */}
          <label htmlFor={id} className="text-capitalize">{label}</label>
          {props.markReq && props.markReq === true && markRequired()}
        </div>    
            <InputTextarea 
            id={field.name} 
            {...field} 
            rows={rows ? rows : 2} 
            cols={cols} 
            value={field.value}
            placeholder={placeholder}           
            /> 
            <small className="text-danger " style={{marginTop:"5px"}}>{props.fieldState.invalid ? props.errors[props.name]?.message : ''}</small>                 
        </div>
    )

}

export default DoTextareaField;

