import React, { useState, useRef, useEffect } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import fetchMethodRequest from '../../../config/service';

const DragAndDropTables = (props) => {
  const [selectedAllData, setSelectedAllData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);

  const [item, setItem] = useState(props.item);
  const [field, setField] = useState(props.field);
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState([]);
  // const [hasDependentData, setHasDependentData] = useState();

  useEffect(() => {
    getDataFromServer();
  }, []);

  // Fetch data from the server based on the provided item configuration
  const getDataFromServer = () => {
    let apiUrl = item.searchApi
    let filterCriteria = {
      sortfield: "created",
      direction: "desc",
      criteria: []
    };

    // Add dependency field criteria if applicable
    if (item.hasDependencyField && props.getValues(item.hasDependencyField) && props.getValues(item.hasDependencyField)["_id"]) {
      filterCriteria['criteria'].push({
        "key": item.fieldForKey,
        "value": props.getValues(item.hasDependencyField)["_id"],
        "type": "eq"
      })
    }

    // Make API request and update state with data
    fetchMethodRequest('GET', `${apiUrl}?filter=${JSON.stringify(filterCriteria)}`).then(async (res) => {
      if (res[apiUrl]) {
        // let selData = field.value ? field.value : [];
        let selData = props.data && props.data[item.name] && props.data[item.name].length > 0 ? props.data[item.name] : [];
        let allData = res[apiUrl];
        setData(selData);
        setAllData(allData.filter(obj => selData.every(o => o._id != obj._id)));
        console.log('selData', selData);
      }
    })
  }

  /**
   * Function to perform search based on value and type.
   * @param {String} value - Search value
   * @param {String} type - Type of search (not currently used)
   */
  const search = (value, type) => {

  }

  // const set = () => {
  //   if (item.hasDependencyField && props.getValues(item.hasDependencyField) !== hasDependentData) {
  //     setHasDependentData(props.getValues(item.hasDependencyField))
  //     getDataFromServer();
  //   }
  // }

  /**
    * Function to render the header of the DataTable.
    * @param {Object} e - Event object
    * @param {String} type - Type of DataTable ('all' or 'selected')
    * @returns {JSX.Element} - JSX for the DataTable header
    */
  const header = (e, type) => {
    let title = type == 'all' ? "All" : "Selected"
    title += ' ' + item.label;

    return <div className='d-flex justify-content-between p-0'>
      <h3>{title}</h3>
      {/* <input placeholder='Search by Code' onChange={(e) => search(e.value, type)} /> */}
    </div>
  }

  /**
   * Function to handle shifting of data between tables (left or right).
   * @param {Array} arr - Array of data to be shifted
   * @param {String} type - Type of shift ('left' or 'right')
   */
  const shift = (arr, type) => {
    let arr1 = allData;
    let arr2 = data;
    if (type == 'right') {
      arr2 = [...arr2, ...arr]
      arr1 = arr1.filter((obj) => arr.every((o) => o.code != obj.code))
    } else {
      arr1 = [...arr1, ...arr]
      arr2 = arr2.filter((obj) => arr.every((o) => o.code != obj.code))
    }
    setSelectedAllData([]);
    setSelectedData([]);
    setAllData(arr1);
    setData(arr2);
    field.onChange(arr2);
  }

  /**
    * Function to generate DataTable JSX based on type and column fields.
    * @param {String} type - Type of DataTable ('all' or 'selected')
    * @param {Object} columnFields - Configuration for columns
    * @returns {JSX.Element} - JSX for the DataTable
    */
  const getDatatable = (type, columnFields) => {

    return <div className='' style={{ width: '48%', height: '400px' }}>
      <DataTable value={type == 'all' ? allData : data}
        selection={type == 'all' ? selectedAllData : selectedData}
        onSelectionChange={(e) => type == 'all' ? setSelectedAllData(e.value) : setSelectedData(e.value)}
        header={(e) => header(e, type)}
      >
        <Column selectionMode="multiple" headerStyle={{ width: '3rem' }}></Column>
        {columnFields.map((obj) => {
          return <Column header={obj.header} field={obj.field} style={obj.style ? obj.style : {}} filter={obj.filter ? obj.filter : false} />
        })}
      </DataTable>
    </div>
  }

  return <div className='text-center d-flex align-items-center'>
    {/* {set()} */}
    {getDatatable('all', item.tableFields)}
    <div className='' style={{ width: '4%' }}>
      <i className="pi pi-angle-double-right " style={{ fontSize: '1.5rem' }} onClick={() => shift(allData, 'right')}></i>
      <br />
      <i className="pi pi-angle-right " style={{ fontSize: '1.5rem' }} onClick={() => shift(selectedAllData, 'right')}></i>
      <br />
      <i className="pi pi-angle-left" style={{ fontSize: '1.5rem' }} onClick={() => shift(selectedData, 'left')}></i>
      <br />
      <i className="pi pi-angle-double-left" style={{ fontSize: '1.5rem' }} onClick={() => shift(data, 'left')}></i>
    </div>
    {getDatatable('selected', item.tableFields)}
  </div>
};

export default DragAndDropTables;