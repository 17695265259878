import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import { Button } from 'primereact/button';
import { Calendar } from 'react-big-calendar';
import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import DoAutoCompleteField from '../../Form/Fields/DoAutoCompleteField';
import { Dialog } from 'primereact/dialog';

const CreateSlotModal = ({  eventDate,  startTime, endTime  }) => {
   
    const { control, handleSubmit,setValue,formState: { errors } } = useForm();
    useEffect(() => {
        setValue('eventDate', eventDate);
        setValue('startTime', startTime);
        setValue('endTime', endTime);
      }, [eventDate, startTime, endTime, setValue]);

    const onFormSubmit = data => {
        onSubmit(data);  // Invoke the passed onSubmit function with form data
    };

    return (
        <div>
            <div className="calendar-form-group calendar-form-group-date">
                <label htmlFor="eventDate">Date</label>
                <Controller
                    name="eventDate"
                    control={control}
                    rules={{ required: 'Date is required' }}
                    render={({ field }) => (
                        <PrimeCalendar id="eventDate" {...field} dateFormat="yy-mm-dd" showIcon />
                    )}
                />
                {errors.eventDate && <p>{errors.eventDate.message}</p>}
            </div>

            <div className="calendar-form-group calendar-form-group-time">
                <label htmlFor="startTime">Start Time</label>
                <Controller
                    name="startTime"
                    control={control}
                    rules={{ required: 'Start time is required' }}
                    render={({ field }) => (
                        <PrimeCalendar id="startTime" {...field} timeOnly hourFormat="12" showIcon />
                    )}
                />
                {errors.startTime && <p>{errors.startTime.message}</p>}
            </div>

            <div className="calendar-form-group calendar-form-group-time">
                <label htmlFor="endTime">End Time</label>
                <Controller
                    name="endTime"
                    control={control}
                    rules={{ required: 'End time is required' }}
                    render={({ field }) => (
                        <PrimeCalendar id="endTime" {...field} timeOnly hourFormat="12" showIcon />
                    )}
                />
                {errors.endTime && <p>{errors.endTime.message}</p>}
            </div>
            </div>
    );
};
export default CreateSlotModal;
