import { FileUpload } from 'primereact/fileupload';
import { useState, useEffect } from 'react';
import fetchMethodRequest from '../../../config/service';
import showToasterMessage from '../../../containers/UI/ToasterMessage/toasterMessage';
import config from '../../../config/config';

const DoFileUplaod = (props) => {
    const [filesPreview, setFilesPreview] = useState([]);
    const userClickUpload = props.userClickUpload ? props.userClickUpload : false;

    /**@Edit the Image(@setValue) */
    useEffect(() => {
        if (props && props.field && props.field.value && props.formType == 'edit') {
            setFilesPreview([`${config.imgUrl}${props.imagePath}/${props.field.value}`]);
        }
    }, [])

    const onSelect = (e) => {
        if (props.auto) return;
                let fp = [];
        //Creating blob url for showing preview if enables
        if (props.showPreview) {
            (Array.from(e.files)).forEach(file => {
                fp.push(URL.createObjectURL(file));
            });
            setFilesPreview(fp);
        }
        if (!userClickUpload) {
            uploadFilesToServer(props.multiple ? e.files : e.files[0])
        }
    }

    const uploadFilesToServer = (data) => {
        fetchMethodRequest('POST', props.url, data, 'upload')
            .then(async (response) => {
                if (response && response.respCode) {
                    if ((response.fileName || response.fineName) && (!props.onChange || props.onChange(response))) {
                        let fileName = response.fileName ? response.fileName : response.fineName;
                        let data = JSON.parse(localStorage.getItem('loginCredentials'))
                        data.photo = fileName;
                        //Setting field value in form
                        if (props.field) {
                            props.field.onChange(fileName)
                        }
                        localStorage.setItem('loginCredentials', JSON.stringify(data))
                        if (response.respMessage == '204' || response.respCode == 204) {
                            if (this.props.type !== 'profile') {
                                showToasterMessage('Bulk upload sucessful', 'success');
                            }
                        }
                    }

                } else if (response && response.errorMessage) {
                    showToasterMessage(response.errorMessage, 'error');
                } else {

                    if (response.sucess && response.sucess.respMessage) {
                        showToasterMessage(response.sucess.respMessage, 'success');
                    } else if (response.failure && response.failure.errorMessage) {
                        showToasterMessage(response.failure.errorMessage, 'error');
                    }
                    if (this.props.close) {
                        this.props.close();
                    }
                }
            }).catch((err) => {
                return err;
            });

    }
    const upload = (e) => {
        if (userClickUpload) {
            uploadFilesToServer(props.multiple ? e.files : e.files[0]);
        }
    }



    return (

        <div className='d-flex flex-column'>
            <FileUpload
                mode={props.mode ? props.mode : 'basic'}
                name={props.name ? props.name : 'file'}
                accept={props.acceptType ? props.acceptType : '*'}
                maxFileSize={props.maxFileSize ? props.maxFileSize : 2000000}
                onSelect={onSelect}
                customUpload
                uploadHandler={upload}
                multiple={props.multiple ? props.multiple : true}
                chooseLabel={props.label ? props.label : 'Select File'} />
            {(props.showPreview && props.mode !== 'advanced') &&
                <div className='my-2'>
                    {filesPreview.map((fSrc) => <img src={fSrc} style={{ height: '5rem', width: '5rem' }} />)}
                </div>
            }
        </div>


    )

}

export default DoFileUplaod;