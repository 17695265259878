import React, { useEffect, useState } from "react";
import DataTables from "../../CommonDataTable/DataTable";
import config from "../../../../config/config";
import apiCalls from "../../../../config/apiCalls";
import ViewModal from "../../CommonModals/viewModal";
import NewUserModal from "../../CommonModals/NewUserModal";
import showToasterMessage from "../../../UI/ToasterMessage/toasterMessage";
import FormModal from "../../../Form/FormModal";
import store from "../../../App/store";
import filePath from "../../../../config/configSampleFiles";
import fetchMethodRequest from "../../../../config/service";
import RolePermissions from "../../CommonModals/Permissions";
import { useParams } from "react-router";



// config file
const treatmentsPlan = (props) => {
  const [languageData, setLanguageData] = useState();
  const [rolePermission, setRolePermission] = useState();
  const [editSelectedRecord, setEditSelectedRecord] = useState(
    window.location.href.includes("edit")
  );
  const [addSelectedRecord, setAddSelectedRecord] = useState(
    window.location.href.includes("create")
  );
  const [viewSelectedRecord, setViewSelectedRecord] = useState(
    window.location.href.includes("view")
  );
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [openNewUserModal, setopenNewUserModal] = useState(false);
  const [item, setItem] = useState({});
  const [newFormFields, setNewFormField] = useState([]);

  const params = useParams();
  useEffect(() => {
    let screenPermissions = RolePermissions.screenPermissions("Treatment Plan");
    if (screenPermissions) {
      setRolePermission(screenPermissions);
    }
  }, []);

  const getDerivedStateFromProps = (props, state) => {
    let storeData = store.getState();
    let languageData =
      storeData &&
        storeData.settingsData &&
        storeData.settingsData.settings &&
        storeData.settingsData.settings.languageTranslation
        ? storeData.settingsData.settings.languageTranslation
        : "";
    return { languageData: languageData };
  };

  const setData = (props) => {
    let rowData = {};
    if (params.id) {
      rowData["_id"] = JSON.parse(params.id);
      return rowData;
    }
  };

  const getTableFields = () => {

    let StatusTypes = [
      {
        label: "All",
        value: null,
      },
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];
    const tableFields = [
      {
        header:'Sno',
        field:'sno'
      },{
        header:'Code',
        field:'code'
      },{
        header:'Description',
        field:'description',
        bodyStyle: {width: '400px', wordBreak: 'break-all', whiteSpace: 'pre-line', maxHeight: '100px',overflowY: 'auto', }
      },{
        header:'Fee',
        field:'displayFee'
      },{
        header:'Category',
        field:'category'
      },{
        header:'Abbreviation',
        field:'abbreviation'
      }
    ];
    let data = [
      {
        type:'expander',
        textAlign: "center",
        width: 20,
        field: "expander",
        label: "expander",
        // header: "Procedure Steps",
        expandField:'procedureSteps',
        tableFields:tableFields,
        show: true,
        mobile: true,
        "textAlign": "center",
        displayInSettings: true,        
      },
      {
        textAlign: "center",
        width: 40,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 47,
        "field": "Sno",
        "header": "Sno",
        "filter": false,
        "sortable": false,
        "placeholder": "Search",
        displayInSettings: true,
      },
      {
        name: "hospitalName",
        type: "relateAutoComplete",
        placeholder: "Hospital Name",
        label: "Hospital Name",
        width: "150px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Practice Name",
        derivedValue: "hospitalName=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalName",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        searchField: "hospitalName",
        populteFields: [],
        displayFields: [],
        controllerName: "hospitals",
        searchApi: "hospitals",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        filter: true,
        field: "hospitalId",
        fieldType: "relateAutoComplete",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      // {
      //   name: "hospitalName",
      //   type: "relateAutoComplete",
      //   placeholder: "Hospital Name",
      //   label: "Hospital Name",
      //   width: "150px",
      //   addFormOrder: 6,
      //   editFormOrder: 6,
      //   header: "Hospital Name",
      //   derivedValue: "hospitalName=undefined",
      //   capitalizeTableText: false,
      //   sortable: false,
      //   actions: [],
      //   actionsNumber: [],
      //   id: "hospitalName",
      //   displayinaddForm: "true",
      //   displayineditForm: "true",
      //   displayinlist: "true",
      //   isFieldRequired: "false",
      //   required: false,
      //   displayOptionsInActions: false,
      //   globalSearchField: "false",
      //   controllerId: 1001,
      //   searchField: "hospitalID",
      //   populteFields: [],
      //   displayFields: [],
      //   controllerName: "hospitals",
      //   searchApi: "hospitals",
      //   isMultiple: false,
      //   textAlign: "Center",
      //   show: true,
      //   filter: true,
      //   field: "hospitalId",
      //   fieldType: "relateAutoComplete",
      //   showOrHideFields: [],
      //   mobile: true,
      //   displayInSettings: true,
      // },
      {
        name: "patientId",
        type: "uniqueField",
        placeholder: "PatientID",
        label: "PatientID",
        width: "120px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "PatientID",
        derivedValue: "patientId=undefined",
        capitalizeTableText: false,
        sortable: false,
        id: "patientId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: 1008,
        textAlign: "Center",
        show: true,
        field: "patientId",
        filter: true,
        searchField: "patientId",
        fieldType: "relateAutoComplete",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "heading",
        type: "uniqueField",
        placeholder: "Heading",
        label: "Heading",
        width: "120px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Heading",
        derivedValue: "heading=undefined",
        capitalizeTableText: false,
        sortable: false,
        id: "heading",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        required: false,
        globalSearchField: "false",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "heading",
        filter:true,
        showOrHideFields: [],
        fieldName: "heading",
        mobile: true,
        displayInSettings: true,
      },{
        name: "note",
        type: "textarea",
        placeholder: "Note",
        label: "Note",
        width: "100px",
        addFormOrder: 11,
        editFormOrder: 11,
        header: "Note",
        derivedValue: "note=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "note",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "note",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        // "actions": [],
        // "actionsNumber": [],
        id: "created",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: config.monthDateFormat,
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true
      },
      {
        "name": "updated",
        "type": "date",
        "placeholder": "Updated",
        "label": "Updated",
        "width": 90,
        "header": "Updated",
        "derivedValue": "updated=undefined",
        // "actions": [],
        // "actionsNumber": [],
        "id": "updated",
        "displayinaddForm": "false",
        "displayineditForm": "false",
        "displayinlist": "true",
        "globalSearchField": "false",
        "controllerId": null,
        "fieldType": "Date",
        "dateFormat":config.monthDateFormat,
        "textAlign": "Center",
        "disabled": true,
        "show": true,
        "field": "updated",
        "mobile": true,
        "displayInSettings": true
      },
      {
        "show": true,
        "textAlign": "center",
        "width": 60,
        "fieldType": "Actions",
        "field": "Actions",
        "header": "Actions",
        "filter": false,
        "sortable": false,
        displayInSettings: true,
      }
    ];
    return data;
  };

  const getFormFields = () => {
    let statusTypes = [
      {
        label: "Active",
        value: "Active",
      },
      {
        label: "Inactive",
        value: "Inactive",
      },
      {
        label: "Pending",
        value: "Pending",
      },
    ];

    // The Procedure Steps table Fields to Display 
    const procedureStepsTableFields = [
      {field : 'code' ,header : 'Code' ,filter:true},
      {field : 'description' ,filter:true ,header : 'Description'},
      {field : 'treatmentarea' ,header : 'Treatmentarea' ,},
      {field : 'category' ,header : 'Category' ,},
      {field : 'abbreviation' ,header : 'Abbreviation' ,},
      {field : 'displayFee' ,header : 'Fee' ,},
    ]
    return [
      {
        name: "hospitalId",
        type: "relateAutoComplete",
        placeholder: "Practice ID",
        label: "Practice ID",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Practice ID",
        derivedValue: "hospitalId=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "hospitalId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        searchField: "hospitalID",
        fieldType: "relateAutoComplete",
        populateField: "hospitalName",
        populateValue: "hospitalID",
        controllerName: "hospitals",
        searchApi: apiCalls.hospitals,
        isMultiple: false,
        textAlign: "Center",
        show: true,
        tableFilterKeys : ['hospitalName'],
        field: "hospitalId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },{
        name: "patientId",
        type: "relateAutoComplete",
        placeholder: "Patient ID",
        label: "Patient ID",
        width: "150px",
        addFormOrder: 1,
        editFormOrder: 1,
        header: "Patient ID",
        derivedValue: "patientId=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "patientId",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1008,
        hasDependency: "true",
        fieldForKey: "hospitalId",
        hasDependencyField: "hospitalId",
        searchField: "patientId",
        fieldType: "relateAutoComplete",
        populateValue: "patientId",
        populateField: "firstName",
        controllerName: "patients",
        searchApi: "patients",
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "patientId",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },{
        name: "heading",
        type: "text",
        placeholder: "Heading",
        label: "Heading",
        width: "150px",
        addFormOrder: 3,
        editFormOrder: 3,
        header: "heading",
        derivedValue: "heading=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "heading",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "false",
        controllerId: 1001,
        isMultiple: false,
        textAlign: "Center",
        show: true,
        field: "heading",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "note",
        type: "textarea",
        placeholder: "Note",
        label: "Note",
        width: "200px",
        addFormOrder: 5,
        editFormOrder: 5,
        derivedValue: "note=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "note",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: true,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        show: true,
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
        isAddFormHidden: false,
        isEditFormHidden: false,
      },
      {
        name:'procedureSteps',
        type:'tableItemsSelector',
        label:'Procedures',
        tableFields:procedureStepsTableFields,//TableFields to display
        searchApi: apiCalls.procedures,//Api call for the Procedures
        show: true,
        hasDependency: true,
        fieldForKey: "hospitalId",
        hasDependencyField: "hospitalId",
      }
    ];
  };

  const submit = async (item) => {
    setIsOpenFormModal(true);
    // await this.formModalRef.getRowData(item, 'edit');
  };
  const getMobileTableFields = () => {
    let data = [
      {
        textAlign: "center",
        width: 47,
        field: "Check Box",
        label: "Check Box",
        fieldType: "multiple",
        header: "",
        selectionMode: "multiple",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        textAlign: "center",
        width: 47,
        field: "Sno",
        label: "SNo",
        header: "SNo",
        filter: false,
        sortable: false,
        placeholder: "Search",
        show: true,
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "name",
        type: "text",
        placeholder: "Name",
        label: "Name",
        header: "Name",
        width: 110,
        id: "name",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        controllerName: null,
        fieldType: "Link",
        textAlign: "Center",
        globalSearchField: "true",
        show: true,
        field: "name",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "createdByName",
        type: "Text",
        placeholder: "Created By",
        label: "Created By",
        width: 120,
        header: "Created By",
        id: "createdByName",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: 1001,
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        field: "createdByName",
        mobile: true,
        filter: true,
        displayInSettings: true,
      },
      {
        name: "updatedByName",
        type: "Text",
        placeholder: "Updated By",
        label: "Updated By",
        width: 120,
        header: "Updated By",
        id: "updatedByName",
        displayinlist: "true",
        fieldType: "Text",
        textAlign: "Center",
        show: true,
        filter: true,
        field: "updatedByName",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "created",
        type: "date",
        placeholder: "Created",
        label: "Created",
        width: 90,
        header: "Created",
        derivedValue: "created=undefined",
        actions: [],
        actionsNumber: [],
        id: "created",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "created",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "updated",
        type: "date",
        placeholder: "Updated",
        label: "Updated",
        width: 90,
        header: "Updated",
        derivedValue: "updated=undefined",
        actions: [],
        actionsNumber: [],
        id: "updated",
        displayinaddForm: "false",
        displayineditForm: "false",
        displayinlist: "true",
        globalSearchField: "false",
        controllerId: null,
        fieldType: "Date",
        dateFormat: "YYYY-MM-DD HH:mm:ss",
        textAlign: "Center",
        disabled: true,
        show: true,
        field: "updated",
        mobile: true,
        displayInSettings: true,
      },
      {
        name: "Questions",
        type: "textarea",
        placeholder: "Questions",
        label: "Questions",
        width: "200px",
        addFormOrder: 6,
        editFormOrder: 6,
        header: "Questions",
        derivedValue: "Questions=undefined",
        capitalizeTableText: false,
        sortable: false,
        actions: [],
        actionsNumber: [],
        id: "Questions",
        displayinaddForm: "true",
        displayineditForm: "true",
        displayinlist: "true",
        isFieldRequired: "false",
        required: false,
        displayOptionsInActions: false,
        globalSearchField: "true",
        controllerId: null,
        textAlign: "Center",
        show: true,
        field: "Questions",
        showOrHideFields: [],
        mobile: true,
        displayInSettings: true,
      },
    ];
    return data;
  };
  const closeFormModal = async () => {
    setIsOpenFormModal(false);
  };

  const cancelReset = async (type) => {
    setopenNewUserModal(false);
    window.location.reload();
  };

  const saveDataToServer = async (item, field, value) => {
    let body = item[0];
    body[field] = value;
    let userBody = Object.assign({}, body);
    // this.setState({
    //   isLoading: true
    // });
    if (body) {
      let method, apiUrl;
      method = "PUT";
      apiUrl = `${apiCalls.questions}/${body._id}`;
      return fetchMethodRequest(method, apiUrl, userBody)
        .then(async (response) => {
          // let sessionexpired = await localStorage.getItem('sessionexpired')
          // if (sessionexpired === "true") {
          //   await this.setState({ sessionExpiryModal: true })
          // }
          if (response && response.respCode) {
            showToasterMessage(response.respMessage, "success");
          } else if (response && response.errorMessage) {
            showToasterMessage(response.errorMessage, "error");
          }
          // this.setState({
          //   isLoading: false
          // });
        })
        .catch((err) => {
          return err;
        });
    } else {
      return;
    }
  };


  return (

    <span>
      {
        <DataTables
          {...props}
          getTableFields={getTableFields}
          formFields={getFormFields}
          addRequired={rolePermission && rolePermission == "Edit" ? true : false}
          editRequired={rolePermission && rolePermission == "Edit" ? true : false}
          deleteRequired={rolePermission && rolePermission == "Edit" ? true : false}
          viewRequired={rolePermission && rolePermission == "Edit" ? true : false}
          exportRequired={rolePermission && rolePermission == "Edit" ? true : false}
          sample={true}
          // globalSearch={'Display Name/Email'}
          // type='Questions'
          // apiUrl={apiCalls.Questions}
          // exportRequried={true}
          printRequried={true}
          actionsTypes={[
            {
              name: "Delete",
              options: [
                {
                  label: "Delete",
                  value: "Delete",
                  show:
                    rolePermission && rolePermission == "Edit" ? true : false,
                  multiple: true,
                },
              ],
            },
          ]}
          settingsRequired={true}
          filterRequired={false}
          gridRequried={true}
          exportToCsv={true}
          dateSearchRequired={false}
          searchInDateRangeField={""}
          setData={setData}
          editSelectedRecord={editSelectedRecord}
          addSelectedRecord={addSelectedRecord}
          viewSelectedRecord={viewSelectedRecord}
          // sampleFilePath={filePath.Treatments}
          globalSearch={"TX Plan/Questions"}
          type="Treatment Plan"
          routeTo={apiCalls.treatmentPlan}
          displayViewOfForm="screen"
          apiResponseKey={apiCalls.treatmentPlan}
          apiUrl={apiCalls.treatmentPlan}
          selectedId={params.id}
        />
      }
    </span>
  );
};

export default treatmentsPlan;