import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TabView, TabPanel } from 'primereact/tabview';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Calendar as PrimeCalendar } from 'primereact/calendar';
import { InputText } from 'primereact/inputtext';
import { ListBox } from 'primereact/listbox';
import { Button } from 'primereact/button';
import { Calendar } from 'react-big-calendar';
import { Dialog } from 'primereact/dialog';
import fetchMethodRequest from '../../../config/service';
import apiCalls from '../../../config/apiCalls';
import DoAutoCompleteField from '../../Form/Fields/DoAutoCompleteField';
import ColorPickerWithTooltip from '../../Form/Fields/ColorPickerWithTooltip';
import UpdateSlotModal from './UpdateSlotModal';


const TabbedInterface = ({
  eventDate,
  startTime,
  endTime,
  control,
  onSubmit,
  setSelectedEvent,
  slots,
  setSelectedEventId,
  onHandleSlotModal,
  currentAction,
  slotModelTrue,
  }) => {

  // const { control, handleSubmit } = useForm();
  const [proceduresData, setProceduresData] = useState([]);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [selectedPatientID, setSelectedPatientID] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [showErrorDialog, setShowErrorDialog] = useState(false);
  // const [activeIndex, setActiveIndex] = useState('patientDetails');



  const {
    handleSubmit,
    register,
    reset,
    setValue,
    getValues,
    formState: { errors },
    // control,
  } = useForm(
      // {resolver: yupResolver(schema),}
    );
  useEffect(() => {
    if(slotModelTrue===0){
      setActiveIndex(0);
      }
    }, [slotModelTrue]);
  useEffect(() => {
    setValue('eventDate', eventDate);
    setValue('startTime', startTime);
    setValue('endTime', endTime);
  }, [eventDate, startTime, endTime, setValue]);
  
  useEffect(() => {
    const elements = document.getElementsByClassName('sketch-picker color-picker');

    if (elements.length > 0) {
      const element = elements[0];

      if (element.childNodes.length > 0) {
        element.childNodes[0].style.display = 'none';
        element.childNodes[1].style.display = 'none';
        element.childNodes[2].style.display = 'none';
      }
    }
  });

  
  const onTabChange = (e) => {
    const isMovingFromPatientDetails = activeIndex === 0;
    const isMovingToAppointmentDetails = e.index === 1; 
    const isPatientIdNotSelected = !selectedPatientID;
  
    if (isMovingFromPatientDetails && isMovingToAppointmentDetails && isPatientIdNotSelected) {
      setShowErrorDialog(true);
    } else {
      setActiveIndex(e.index); 
      if (showErrorDialog) {
        setShowErrorDialog(false); 
      }
      onHandleSlotModal(e.index);
    }
  };

  const renderErrorDialog = () => (
    <Dialog
      visible={showErrorDialog}
      onHide={() => setShowErrorDialog(false)}
      header="Error"
      modal
      closeOnEscape
      draggable={false}>
      <p>Please select a patient ID before proceeding.</p>
    </Dialog>
  );
  const handleFormSubmit = data => {
    console.log(data);
  };

  const onPatientSelect = (selectedOption) => {

    if (!selectedOption || !selectedOption.patientId) {
      setSelectedPatient(null);
      return;
    }
    const patientId = selectedOption.patientId;
    const patientDetailsUrl = `patients`;

    fetchMethodRequest('GET', apiCalls.patients)
      .then(response => {
        const patientDetails = response.patients.find(patient => patient.patientId === patientId);
        if (patientDetails) {
          setSelectedPatient(patientDetails);
        } else {
          console.error('Patient not found');
          setSelectedPatient(null);
        }
      })
      .catch(error => {
        console.error('Error fetching patient details:', error);
        setSelectedPatient(null);
      });
  };
  let getAutoComplete = (i, item) => {
    const itemTemplate = (option) => {
      const displayText = item.showField
        ? `${option[item.searchField]} (Name: ${option[item.showField]})`
        : option[item.searchField];

      return (
        <div className="autocomplete-item">
          <div>{displayText}</div>
        </div>
      );
    };

    const handlePatientSelect = (value) => {
      setSelectedPatientID(value);

      onPatientSelect(value);

          };

    return (
      <div>
        <Controller
          name={item.name}
          control={control}
          render={({ field, fieldState }) => (
            <DoAutoCompleteField
              markReq={item.required}
              input={field}
              id={field.id}
              name={field.name}
              field={field}
              filterField={item.filterField}
              filterValue={item.filterValue}
              filterType={item.isNotEq}
              multiple={item.isMultiple}
              fieldState={fieldState}
              errors={errors}
              screen={'Appointments'}
              searchApi={item.searchApi}
              itemTemplate={itemTemplate}
              searchField={item.searchField}
              onSelectionChange={onPatientSelect}
              // allow={props.allowDuplicates}
              filterFieldType={item.filterFieldType ? item.filterFieldType : null}
              placeholder={item.placeholder}
              label={item.label}
              item={item}
              onPatientSelect={handlePatientSelect}
              formType={"add"}
              disabled={item.disabled}
              populateValue={item.searchField}
              populateField={item.showField}
            />)}
        />
      </div>
    )
  }
  const fetchProcedures = () => {



    fetchMethodRequest('GET', apiCalls.procedures)
      .then(async (response) => {
        if (response && response.procedures) {
          let proceduresData = response.procedures;
          setProceduresData(proceduresData);

        }
      }).catch(error => {
        console.error('Error fetching procedures:', error);
      });
  };


  useEffect(() => {
    fetchProcedures();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    // getMonth() returns a 0-indexed month, so adding 1
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };



  const patientDetailsToDataTable = (patient) => {
    if (!patient) return [];

    return [
      { label: 'Name', value: `${patient.name}` },
      { label: 'Birthdate', value: formatDate(patient.dateOfBirth) },
      { label: 'Phone', value: patient.phoneNumber },
      { label: 'TelePhone Number', value: patient.telePhoneNumber },
      { label: 'City', value: patient.city },
      { label: 'Zip Code', value: patient.zipCode },
    ];
  };

  const patientDetailsData = selectedPatient ? patientDetailsToDataTable(selectedPatient) : [];



  return (
    <div>
      {renderErrorDialog()}
    <TabView onTabChange={(e)=>onTabChange(e)} activeIndex={activeIndex}>

      <TabPanel header="Patient Details">
        <div>
          <div>

            {getAutoComplete(1, { name: 'patientId', type: 'text', label: 'Patient Id', searchApi: "patients", searchField: "patientId", showField: "firstName",disabled:"true" })}
          </div>
          <div style={{ marginTop: '15px' }}>

            {selectedPatient && (
              <DataTable value={patientDetailsData} header="Patient Details" responsiveLayout="scroll">
                <Column field="label" header="Detail" />
                <Column field="value" header="Information" />
              </DataTable>
            )}
          </div>
        </div>
      </TabPanel>
      <TabPanel header="Appointment Details">
        {/* <form onSubmit={handleSubmit(handleFormSubmit)} className="calendar-form"> */}
        <div className="calendar-form-group calendar-form-group-date">
          <label htmlFor="eventDate">Date</label>
          <Controller
            name="eventDate"
            control={control}
            render={({ field }) => (
              <PrimeCalendar id="eventDate" {...field} dateFormat="yy-mm-dd" showIcon />
            )}
          />
        </div>

        <div className="calendar-form-group-row">
          <div className="calendar-form-group calendar-form-group-time">
            <label htmlFor="startTime">From Time</label>
            <Controller
              name="startTime"
              control={control}
              render={({ field }) => (
                <PrimeCalendar id="startTime" {...field} timeOnly hourFormat="24" showIcon />
              )}
            />
          </div>

          <div className="calendar-form-group calendar-form-group-time">
            <label htmlFor="endTime">To Time</label>
            <Controller
              name="endTime"
              control={control}
              render={({ field }) => (
                <PrimeCalendar id="endTime" {...field} timeOnly hourFormat="24" showIcon />
              )}
            />
          </div>
        </div>
        <div>
          {getAutoComplete(1, { name: 'provider', type: 'text', label: 'provider', searchApi: "users", searchField: "name" })}
        </div>
        <div className="calendar-form-group">
          <label htmlFor="color">Color</label>
          <Controller
            name="color"
            control={control}
            render={({ field }) => (
              <ColorPickerWithTooltip field={field} />
            )}
          />
        </div>

        {/* </form> */}
      </TabPanel>
      <TabPanel header="Procedures">
        <DataTable value={proceduresData} responsiveLayout="scroll">
          <Column field="code" header="Code" sortable></Column>
          <Column field="description" header="Description" sortable filter filterPlaceholder="Search by description"></Column>
          <Column field="fee" header="Fee" sortable></Column>
        </DataTable>
      </TabPanel>

      <TabPanel header="Slot details">
        <UpdateSlotModal
          control={control}
          setSelectedEvent={setSelectedEvent}
          slots={slots}
          setSelectedEventId={setSelectedEventId}
        />
      </TabPanel>
      
     
    </TabView>
    </div>

  );
};

export default TabbedInterface;
